<div class="toast-list" >
	<div *ngFor="let toast of ToastMessages" class="toast-message fade-in" [ngClass]="CalculateClasses(toast)">
		<img src="../assets/toast/error.png" *ngIf="toast.Type === ToastType.Error"/>
		<img src="../assets/toast/info.png" *ngIf="toast.Type === ToastType.Info"/>
		<span class="toast-header">{{toast.Type}} Message</span>
		<span class="close-button material-icons-outlined" (click)="Close(toast)">close</span>
		<p class="toast-body">{{toast.Message}}</p>
		<div *ngIf="toast.Action && toast.ActionTitle" class="toast-action-container">
			<button class="toast-action crown-blue-button" (click)="PerformToastAction(toast)">{{toast.ActionTitle}}</button>
		</div>
	</div>
</div>
