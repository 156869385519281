/**
 * Defines the identifiers for the events related to installing the PWA.
 */
export class InstallPwaEventsConstants {
	/**
 	* The window event triggered when the PWA has been installed.
 	* @see https://developer.mozilla.org/en-US/docs/Web/API/Window/appinstalled_event
 	*/
	 public static readonly APP_INSTALLED_EVENT = "appinstalled";

	 /**
	  * The window event triggered when the browser has detected that a website can be installed as a PWA.
	  * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeinstallprompt_event
	  */
	 public static readonly BEFORE_INSTALL_PROMPT_EVENT = "beforeinstallprompt";
}