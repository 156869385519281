<crown-x-dialog #UnavailableLicensesDialog DialogTitle="Unavailable Licenses" (OkButtonClick)="OnUnavailableLicensesOkClick()" (CancelButtonClick)="OnUnavailableLicensesCancelClick()">
	The following licenses are not available: {{UnallocatedLicenses.join(", ")}}
</crown-x-dialog>
<div class="page-content">
	<crown-x-form-box>
		<crown-x-form-box-header header Title="Select an Agent" IconName="business" IconCssId="material-icons-outlined"></crown-x-form-box-header>
		<div body>
			<div *ngIf="Loading && !ShowError" class="loading-text">Loading...</div>
			<div *ngIf="ShowError" class="no-data-text">There are no agents associated with this user.</div>
			<div *ngIf="!ShowError && !Loading" class="agent-selection-container">
				<label for="crown-ids">Agents</label>
				<select name="crown-ids" #crownIdSelect id="crown-ids" (change)="OnSelectCrownIdentifier(crownIdSelect.value)">
					<option *ngFor="let agent of AuthorizedAgents" [value]="agent.CrownIdentifier">{{agent.CompanyName}}</option>
				</select>
			</div>
		</div>
	</crown-x-form-box>
</div>