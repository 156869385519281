import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from './../../authentication/authentication.service';
import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

/**
 * This component displays the base layout for all pages.
 */
@Component({
	selector: 'crownx-base-layout',
	templateUrl: './base-layout.component.html',
	styleUrls: ['./base-layout.component.css']
})
export class BaseLayoutComponent {

	/**
	 * Represents the current page url.
	 */
	private currentUrl: string = "";

	/**
	 * Used to unsubscribe from subscriptions within the component.
	 */
	private unsubscriber = new Subject();

	/**
	 * The url for the login page.
	 */
	private readonly loginUrl: string = "login";

	/**
	 * The url for the logout page.
	 */
	private readonly logoutUrl: string = "logout";

	/**
	 * @constructor
	 * @param router The Angular router service.
	 * @param authenticationService The authentication service.
	 */
	constructor(
		private readonly router: Router,
		private readonly authenticationService: AuthenticationService
	) {
		this.router.events.pipe(takeUntil(this.unsubscriber)).subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.currentUrl = this.router.url;
			}
		});
	 }

	/**
	 * Component initialization.
	 */
	public ngOnInit(): void {
		this.currentUrl = this.router.url;
	}

	/**
	 * Component deconstruction.
	*/
	public ngOnDestroy(): void {
		this.unsubscriber.next(null);
		this.unsubscriber.complete();
	}

	/** Indicates if the user is authenticated. */
	public get IsAuthenticated(): boolean {
		return this.authenticationService.IsAuthenticated;
	}

	/**
	 * Indicates if the Title bar should be showing.
	 * @returns True if the current url matches the url for the login or logout page.
	 * @readonly
	 */
	public get ShowTitleBar(): boolean {
		return this.currentUrl != `/${this.loginUrl}` && this.currentUrl != `/${this.logoutUrl}`;
	}
}