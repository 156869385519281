<div class="crown-light-blue-background-hover menu-icon" #HamburgerMenuIcon>
	<button id="hamburger-menu-icon" class="no-style" (click)="ToggleMenu()">
		<span class="material-icons-outlined">menu</span>
	</button>
</div>
<nav class="navbar-container flex-row" [class.slide-in-left]="IsHamburgerMenuOpen" [class.open-menu-container]="IsHamburgerMenuOpen" #NavigationMenu>
	<div *ngFor="let item of MenuItemsToShow" class="navbar-item crown-light-blue-background-hover" [class.open-menu-item]="IsHamburgerMenuOpen">
		<a *ngIf="!item.OpenInNewTab" [routerLink]="[item.Url]" (click)="TrackEvent(item.TelemetryIdentifier)">
			<span class="navbar-text">{{item.Title}}</span>
		</a>
		<a *ngIf="item.OpenInNewTab" href="{{item.Url}}" target="_blank" rel="noopener" (click)="TrackEvent(item.TelemetryIdentifier)">
			<span class="navbar-text">{{item.Title}}</span>
		</a>
	</div>
	<div class="user-actions" [class.visible-user-actions]="IsHamburgerMenuOpen">
		<div class="user-info">
			<div class="username" id="menu-username">{{UserName}}</div>
			<div *ngIf="CompanyName" id="menu-company-name">{{CompanyName | uppercase}}</div>
		</div>
		<div class="open-menu-item crown-light-blue-background-hover" *ngIf="HasMultipleCrownIds">
			<a (click)="ChangeCompany()">
				<span class="navbar-text">Switch</span>
			</a>
		</div>
		<div class="open-menu-item crown-light-blue-background-hover">
			<a (click)="Logout()" id="logout">
				<span class="navbar-text">Logout</span>
			</a>
		</div>
	</div>
</nav>