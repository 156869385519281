import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

/**
 * Service that keeps track of the online status of the webpage
 */
@Injectable({
	providedIn: 'root'
})
export class NetworkConnectivityService {

	/**
	 * Status if there is currently an internet connection.
	 */
	public IsOnline: boolean = true;

	/**
	 * Emitter for telling all subscribes the online status.
	 */
	private onlineChangedEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

	/**
	 * Registers window event listeners for online/offline
	 */
	constructor() {
		window.addEventListener('offline', () => {
			this.SetOnlineStatus(false);
		});
		window.addEventListener('online', () => {
			this.SetOnlineStatus(true);
		});
	}

	/**
	 * Sets the online status and emits the result to the observable.
	 * @param status The online status.
	 */
	private SetOnlineStatus(status: boolean): void {
		this.IsOnline = status;
		this.onlineChangedEmitter.emit(this.IsOnline);
	}

	/**
	 * Creates an observable to be able to watch the when online connectivity changes.
	 * @returns Observable for online changes.
	 */
	public GetOnlineObserver$(): Observable<boolean> {
		return this.onlineChangedEmitter.asObservable();
	}
}
