import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayShadowComponent } from './overlay-shadow.component';

/**
 * The module that houses the overlay shadow.
 */
@NgModule({
	declarations: [
		OverlayShadowComponent
	],
	imports: [
		CommonModule
	],
	exports: [
		OverlayShadowComponent
	]
})
export class OverlayShadowModule { }
