<div class="page-content">
	<crown-x-form-box>
		<crown-x-form-box-header header Title="Crown X" IconName="business" IconCssId="material-icons-outlined"></crown-x-form-box-header>
		<div body>
			<div *ngIf="Loading" class="loading-text">Loading...</div>
			<div *ngIf="!Loading">
				<ul>
					<li *ngFor="let item of NavigationLinks" class="feature-link">
						<a [routerLink]="[item.Url]" class="navitem crown-link-blue" (click)="TrackEvent(item.TelemetryIdentifier)">
							<span>{{item.Title}}</span>
						</a>
					</li>
				</ul>
			</div>
		</div>
	</crown-x-form-box>
</div>