import { DialogService } from './dialog/dialog.service';
import { AuthenticationService } from './authentication/authentication.service';
import { UserSessionDataService } from './authentication/user-session-data.service';
import { Component, OnInit, OnDestroy, HostListener, ViewChild } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { LoggerService } from './telemetry/logger.service';
import { VersionDataService } from './version-data.service';
import { UserSessionState, UserSessionStateType } from './authentication/user-session-state';
import { Router } from '@angular/router';
import { DialogComponent } from './dialog/dialog/dialog.component';
import { TelemetryIdentifiers } from './telemetry/telemetry-identifiers';
import { UserStateService } from './user-state/user-state.service';
import { AuthorizationService } from './authorization/authorization.service';

/**
 * The root component for the application.
 */
@Component({
	selector: 'crownx-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

	/** A reference to the dialog for user session timeout. */
	@ViewChild("UserSessionTimeoutDialog")
	public UserSessionTimeoutDialog!: DialogComponent;

	/** A reference to the dialog for user logged in elsewhere. */
	@ViewChild("UserLoggedInElsewhereDialog")
	public UserLoggedInElsewhereDialog!: DialogComponent;

	/** Used to unsubscribe from observables. */
	private unsubscriber = new Subject();

	/**
	 * @constructor
	 *
	 * @param versionDataService The version data service.
	 * @param logger The logging service.
	 * @param userSessionDataService The user session data service.
	 * @param authenticationService The authentication service.
	 * @param router The Angular router service.
	 * @param dialogService The dialog service.
	 * @param userStateService The user state service.
	 * @param authorizationService The authorization service.
	 */
	constructor(
		private readonly versionDataService: VersionDataService,
		private readonly logger: LoggerService,
		private readonly userSessionDataService: UserSessionDataService,
		private readonly authenticationService: AuthenticationService,
		private readonly router: Router,
		private readonly dialogService: DialogService,
		private readonly userStateService: UserStateService,
		private readonly authorizationService: AuthorizationService
	) { }

	/**
	 * Component initialization.
	 */
	public ngOnInit(): void {
		this.logger.TrackTrace(`Environment version: ${environment.Version}`);
		let isFirstPass: boolean = true;
		timer(0, 60000).subscribe(() => {
			this.versionDataService.GetVersion$()
				.pipe(takeUntil(this.unsubscriber))
				.subscribe((version: string) => {
					if (isFirstPass) {
						this.logger.TrackTrace(`Server version: ${version}`);
						isFirstPass = false;
					}
					if (version != environment.Version)
					{
						this.logger.TrackTrace(`Client version ${environment.Version} does not match server version ${version}.`);
					}
			});
		});
		let lastUserStateCheckSeconds: number = 0
		let isInitial: boolean = true;
		timer(0, 1000).subscribe(() => {
			// Poll the backend right away and every 15 seconds vs every 1 second (that the timer is set to).
			if (isInitial || lastUserStateCheckSeconds == 15) {
				lastUserStateCheckSeconds = 0;
				if (this.authenticationService.IsAuthenticated) {
					// Change isInitial only after the user has authenticated so the user state is checked right when the user is authenticated.
					isInitial = false;
					this.userSessionDataService.GetState$()
					.pipe(takeUntil(this.unsubscriber))
					.subscribe((userSessionState: UserSessionState) => {
						switch (userSessionState.State) {
							case UserSessionStateType.TimedOut:
								this.dialogService.ShowModalDialog(this.UserSessionTimeoutDialog);
								this.authorizationService.ClearUserAuthorization();
								this.router.navigate(["/logout"]);
								break;
							case UserSessionStateType.LoggedInElsewhere:
								if (!this.userStateService.State.FilterTelemetry)
									this.logger.TrackEvent(TelemetryIdentifiers.EVENT_LOGGED_IN_ELSEWHERE, { CrownIdentifier: this.userStateService.State.CrownIdentifier, UserName: this.userStateService.State.Username });
								this.dialogService.ShowModalDialog(this.UserLoggedInElsewhereDialog);
								this.authorizationService.ClearUserAuthorization();
								this.router.navigate(["/logout"]);
								break;
							case UserSessionStateType.Active:
								break;
							default:
								this.logger.TrackError("Unexpected user session state type user session state.", new Error("Unexpected user session state type user session state."));
								break;
						}
					});
				}
			}
			else {
				lastUserStateCheckSeconds++;
			}
		});
	}

	/**
	 * Component destruction.
	 */
	 public ngOnDestroy(): void {
		this.unsubscriber.next(null);
		this.unsubscriber.complete();
	}

	/**
	 * Updates the last active date when a user clicks.
	 */
	@HostListener('document:click')
	public GlobalClick(): void {
		if (this.authenticationService.IsAuthenticated)
			this.userSessionDataService.UpdateLastActive$().pipe(takeUntil(this.unsubscriber)).subscribe();
	}

	/**
	 * Event handler for when okay button is clicked on timeout dialog.
	 */
	public OnTimeoutDialogOkayClick(): void {
		this.dialogService.HideModalDialog(this.UserSessionTimeoutDialog);
	}

	/**
	 * Event handler for when okay button is clicked on logged in elsewhere dialog.
	 */
	 public OnLoggedInElsewhereDialogOkayClick(): void {
		this.dialogService.HideModalDialog(this.UserLoggedInElsewhereDialog);
	}
}