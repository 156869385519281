import { isUserLicensed } from 'src/app/common/common-functions';
import { AuthorizationConstants } from './authorization-constants';
import { AuthorizationService } from './authorization.service';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserStateService } from '../user-state/user-state.service';
import { Authorization } from './authorization';

/**
 * This guard only allows access to pages if the user is authenticated; otherwise it redirects the user to the login page.
 */
@Injectable()
export class AuthorizationGuard  {

	/**
	 * @constructor
	 *
	 * @param router The router used to change views.
	 * @param userStateService The user state service.
	 * @param authorizationService The authorization service.
	 */
	constructor(
		private readonly router: Router,
		private readonly userStateService: UserStateService,
		private readonly authorizationService: AuthorizationService
	) { }

	/**
	 * Overridden method indicating if a route can be activated.
	 */
	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		if (route.data?.feature) {
			return this.CheckFeatureAuthorization$(route.data?.feature);
		}
		else {
			return this.CheckAuthorized$();
		}
	}

	/**
	 * Overridden method indicating if a child route can be loaded.
	 */
	public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		if (route.data?.feature) {
			return this.CheckFeatureAuthorization$(route.data?.feature);
		}
		else {
			return this.CheckAuthorized$();
		}
	}

	/**
	 * Creates an observable that checks if the current session is authenticated and redirects to the login page if it's not.
	 *
	 * @returns An observable that returns whether a route is authenticated.
	 */
	private CheckAuthorized$(): Observable<boolean> {
		const isAuthorized: boolean = (this.userStateService.State.CrownIdentifier != null);
		if (!isAuthorized)
			this.router.navigate(["/authorize"]);

		return of(isAuthorized);
	}

	/**
	 * Checks if a user is authorized for the feature they are trying to access.
	 * @param feature The feature the user is trying to access.
	 * @returns An observable indicating whether the user is authorized for the feature.
	 */
	private CheckFeatureAuthorization$(feature: string): Observable<boolean> {
		return this.authorizationService.GetAuthorization$()
				.pipe(
					map((auth: Authorization) => {
						switch (feature) {
							// User Roles
							case AuthorizationConstants.USER_ROLE_MANAGEMENT_ROLE:
							case AuthorizationConstants.AGENT_MANAGEMENT_ROLE:
							case AuthorizationConstants.CROWN_USER_MANAGEMENT_ROLE:
								return this.UserHasRole(auth.Roles, feature);

							// User Licenses
							case AuthorizationConstants.LIVE_MAP_LICENSE:
							case AuthorizationConstants.ROUTE_PLANNING_LICENSE:
							case AuthorizationConstants.AGENT_USER_MANAGEMENT_LICENSE:
							case AuthorizationConstants.MOBILE_DRIVER_LICENSE:
							case AuthorizationConstants.PICTURE_SLOT_CONFIGURATION_LICENSE:
								return this.UserHasLicense(auth, feature);
							// Default
							default:
								this.router.navigate(["/authorize"]);
								return false;
						}

					}),
					catchError(err => {
						this.router.navigate(["/authorize"]);
						return of(false)
					}),
				);
	}


	/**
	 * Checks if a user has the specified role
	 * @param userRoles The list of the user's current roles.
	 * @param role The role we're trying to check.
	 * @returns A boolean indicating whether the user has the role specified
	 */
	private UserHasRole(userRoles: string[] | null, role: string): boolean {
		if (!userRoles?.includes(role)) {
			// If there are no roles or there are no roles that match the feature, route back to authorize page.
			this.router.navigate(["/authorize"]);
			return false;
		}
		else {
			return true;
		}
	}

	/**
	 * Checks if a user has authorized for an agent, and if they have been allocated the specified license, or if they have the bypass role
	 * @param userAuthorization The user's current authorizations.
	 * @param license The license we're trying to check.
	 * @returns A boolean indicating whether the user has been authorized for the specified license, or if the user has the bypass role
	 */
	private UserHasLicense(userAuthorization: Authorization, license: string): boolean {
		if (!this.CheckAuthorized$() || !isUserLicensed(userAuthorization, license)) {
			// If the user does not have the bypass role and the current authorized licenses do not match the feature or there are no current authorized liceses, route back to authorize page.
			this.router.navigate(["/authorize"]);
			return false;
		}
		else {
			return true;
		}
	}
}