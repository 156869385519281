import { Component } from "@angular/core";

/**
 * This component is used to display form boxes.
 */
@Component({
	selector: 'crown-x-form-box',
	templateUrl: './form-box.component.html',
	styleUrls: ['./form-box.component.css']
})
export class FormBoxComponent {

}