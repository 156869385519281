/**
 * The constants for roles and licenses for authorization.
 */
export class AuthorizationConstants {

	/**
	 * The role used for Agent Management.
	 */
	public static readonly AGENT_MANAGEMENT_ROLE = "Agent Management";

	/**
	 * The role used for Crown User Management.
	 */
	public static readonly CROWN_USER_MANAGEMENT_ROLE = "Crown User Management";

	/**
	 * The role used for User Role Management.
	 */
	public static readonly USER_ROLE_MANAGEMENT_ROLE = "User Role Management";

	/**
	 * The role used for allowing Crown employees access to all crown identifiers and licenses without affecting the agent's license pool.
	 */
	public static readonly AGENT_LICENSE_BYPASS_ROLE = "Agent License Bypass";

	/**
	 * The role used for filtering out client activity telemetry.
	 */
	public static readonly CLIENT_EVENT_TELEMETRY_BYPASS_ROLE = "Client Event Telemetry Bypass";

	/**
	 * The license for being able to access the live map.
	 */
	public static readonly LIVE_MAP_LICENSE = "Live Map";

	/**
	 * The license for being able to perform optimization on a single route plan.
	 */
	public static readonly OPTIMIZE_SINGLE_ROUTE_PLAN: string = "Optimize Single Route Plan";

	/**
	 * The license for being able to access the route planning features.
	 */
	public static readonly ROUTE_PLANNING_LICENSE = "Route Planning";

	/**
	 * The license for being able to access the agent user management pages.
	 */
	public static readonly AGENT_USER_MANAGEMENT_LICENSE = "Agent User Management";

	/**
	 * The license for being able to access the Crown Mobile driver functionality.
	 */
	public static readonly MOBILE_DRIVER_LICENSE = "Mobile Driver";

	/**
	 * The license for being able to access picture configuration.
	 */
	public static readonly PICTURE_SLOT_CONFIGURATION_LICENSE = "Picture Slot Configuration";
}