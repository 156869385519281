import { AuthorizationService } from './authorization/authorization.service';
import { AuthenticationGuard } from './authentication/authentication.guard';
import { ToastModule } from './toast/toast.module';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { BaseLayoutComponent } from './layout/base-layout/base-layout.component';
import { AuthenticatedLayoutComponent } from './layout/authenticated-layout/authenticated-layout.component';
import { LoginComponent } from './authentication/login/login.component';
import { GlobalErrorHandlerService } from './telemetry/global-error-handler.service';
import { LoggerService } from './telemetry/logger.service';
import { AuthenticationService } from './authentication/authentication.service';
import { LogoutComponent } from './authentication/logout/logout.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { TitleService } from './layout/title.service';
import { TitleBarComponent } from './layout/title-bar/title-bar.component';
import { AuthenticationInterceptor } from './authentication/authentication.interceptor';
import { HttpRequestLoggingInterceptor } from './telemetry/http-request-logging.interceptor';
import { SharedModule } from './common/shared.module';
import { DialogModule } from './dialog/dialog.module';
import { ValidationErrorModule } from './validation-error/validation-error.module';
import { AuthorizeComponent } from './authorization/authorize/authorize.component';
import { PageToolbarComponent } from './layout/page-toolbar/page-toolbar.component';
import { UserStateService } from './user-state/user-state.service';
import { AuthorizationGuard } from './authorization/authorization.guard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenuToolbarComponent } from './layout/menu-toolbar/menu-toolbar.component';
import { FormBoxModule } from './common/form-box/form-box.module';
import { NetworkConnectivityComponent } from './layout/network-connectivity/network-connectivity.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { NavigationLandingComponent } from './authorization/navigation-landing/navigation-landing.component';
import { SharedInputModule } from './common/shared.input.module';
import { NotificationBadgeModule } from './notification-badge/notification-badge.module';
import { InstallPromptComponent } from './authentication/login/install-prompt/install-prompt.component';

/**
 * This is the main module for the app.
 */
@NgModule({
	declarations: [
		AppComponent,
		BaseLayoutComponent,
		AuthenticatedLayoutComponent,
		LoginComponent,
		InstallPromptComponent,
		TitleBarComponent,
		LogoutComponent,
		PageNotFoundComponent,
		AuthorizeComponent,
		NavigationLandingComponent,
		PageToolbarComponent,
		MenuToolbarComponent,
		NetworkConnectivityComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		SharedInputModule,
		FormsModule,
		HttpClientModule,
		NotificationBadgeModule,
		RouterModule,
		SharedModule,
		DialogModule,
		ValidationErrorModule,
		ToastModule,
		BrowserAnimationsModule,
		FormBoxModule,
		ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately', scope: '/' }),
	],
	providers: [
		{ provide: ErrorHandler, useClass: GlobalErrorHandlerService },
		{ provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: HttpRequestLoggingInterceptor, multi: true },
		LoggerService,
		AuthenticationService,
		TitleService,
		AuthorizationService,
		AuthenticationGuard,
		AuthorizationGuard,
		HttpClient,
		UserStateService,
	],
	bootstrap: [ AppComponent ]
})
export class AppModule { }