import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { LoggerService } from '../telemetry/logger.service';
import { AgentLicense } from './agent-license';

/**
 * Service used to manipulate and query license data.
 */
@Injectable({
	providedIn: 'root'
})
export class LicenseDataService {

	/** The path to the licenses controller. */
	private static readonly CONTROLLER_PATH: string = "/api/licenses";

	/**
	 * @constructor
	 * @param httpClient The HTTP client to use for web requests.
	 * @param logger The logging service.
	 */
	constructor(
		private readonly httpClient: HttpClient,
		private readonly logger: LoggerService
		) { }

	/**
	 * Gets all available licenses.
	 * @returns An observable with the list of available licenses.
	 */
	public GetAll$(): Observable<AgentLicense[]> {
		try {
			const url: string = LicenseDataService.CONTROLLER_PATH;
			return this.httpClient.get<AgentLicense[]>(url, { withCredentials: true });
		}
		catch (error: any) {
			this.logger.TrackError("Unable to build license request.", error);
			return throwError(error);
		}
	}

	/**
	 * Allocates licenses to the user.
	 * @returns An observable with the list of licenses that could not be allocated.
	 */
	 public AllocateLicenses$(): Observable<string[]> {
		try {
			const url: string = `${LicenseDataService.CONTROLLER_PATH}/allocate`;
			return this.httpClient.post<string[]>(url, null, { withCredentials: true });
		}
		catch (error: any) {
			this.logger.TrackError("Unable to build license request.", error);
			return throwError(error);
		}
	}
}
