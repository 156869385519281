import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AuthorizationService } from '../authorization.service';
import { Authorization } from '../../authorization/authorization';
import { AuthorizationConstants } from '../authorization-constants';
import { isUserLicensed } from '../../common/common-functions';
import { TitleService } from 'src/app/layout/title.service';
import { MenuItem } from 'src/app/layout/menu-item';
import { LoggerService } from 'src/app/telemetry/logger.service';
import { UserStateService } from 'src/app/user-state/user-state.service';
import { NavigationConstants } from 'src/app/common/navigation-constants';

/**
 * This component is used to provide naivation options to users after authorizing with an agent.
 */
@Component({
	selector: 'crown-x-navigation-landing',
	templateUrl: './navigation-landing.component.html',
	styleUrls: ['./navigation-landing.component.css']
})
export class NavigationLandingComponent implements OnInit, OnDestroy {

	/** Indicates if the user authentication is being loaded. */
	public Loading: boolean = true;

	/** The current user authorization. */
	private userAuthorization: Authorization | null = null;

	/** Used to unsubscribe from observables. */
	private unsubscriber = new Subject();

	/**
	 * The collection of navigation menu items.
	 */
	private menuItemsToShow: MenuItem[] = [];

	/**
	 * @constructor
	 *
	 * @param titleService The service used to set the page title.
	 * @param authorizationService The authorization service.
	 * @param router The Angular router service.
	 * @param logger The logging service.
	 * @param userStateService The service used to change and get the user state.
	 */
	constructor(
		private readonly titleService: TitleService,
		private readonly authorizationService: AuthorizationService,
		private readonly router: Router,
		private readonly logger: LoggerService,
		private readonly userStateService: UserStateService
	) { }

	/**
	 * Component initialization.
	 */
	public ngOnInit(): void {
		this.titleService.Title = "Features";
		this.authorizationService.GetAuthorization$()
			.pipe(takeUntil(this.unsubscriber))
			.subscribe((auth: Authorization) => {
				this.userAuthorization = auth;
				this.PopulateMenuItems();
				this.AutoNavigate();
			});
	}

	/**
	 * Component destruction.
	 */
	public ngOnDestroy(): void {
		this.unsubscriber.next(null);
		this.unsubscriber.complete();
	}

	/**
	 * Navigates to the provided route.
	 * @param route The route to which to navigate
	 */
	public Navigate(route: string): void {
		this.router.navigate([route]);
	}

	/**
	 * Gets the list of navigation links to display on the features page.
	 * @readonly
	 * @returns A collection of MenuItems.
	 */
	public get NavigationLinks(): MenuItem[] {
		return this.menuItemsToShow;
	}

	/**
	 * Tracks when a menu item is clicked and logs an event.
	 * @param telemetryIdentifier The telemetry identifier of the menu item that was clicked.
	 */
	public TrackEvent(telemetryIdentifier: string | null): void {
		this.Loading = true;
		if (telemetryIdentifier && !this.userStateService.State.FilterTelemetry) {
			this.logger.TrackEvent(telemetryIdentifier, { CrownIdentifier: this.userStateService.State.CrownIdentifier, UserName: this.userStateService.State.Username });
		}
	}

	/**
	 * Populate menu items based on the user's authorizations.
	 */
	private PopulateMenuItems(): void {
		this.menuItemsToShow = [];
		NavigationConstants.NAVIGATION_MENU_ITEMS.filter((menuItem: MenuItem) => menuItem.ShowOnFeaturesLanding)
			.forEach(item => {
				if ((item.Role == null && item.License == null) ||
					(item.Role != null && (this.userAuthorization?.Roles ?? []).filter((role: string) => role == item.Role).length > 0) ||
					(item.License != null && this.userAuthorization != null && isUserLicensed(this.userAuthorization, item.License))) {
					this.menuItemsToShow.push(item);
				}
			});
	}

	/**
	 * Attempts to auto navigate based on the user's available licenses.
	 */
	private AutoNavigate(): void {
		if (this.userAuthorization!.Roles?.includes(AuthorizationConstants.AGENT_LICENSE_BYPASS_ROLE)) {
			this.Loading = false;
			return;
		}
		const authorizedForLiveMap: boolean = isUserLicensed(this.userAuthorization!, AuthorizationConstants.LIVE_MAP_LICENSE);
		const authorizedForTimeToService: boolean = isUserLicensed(this.userAuthorization!, AuthorizationConstants.ROUTE_PLANNING_LICENSE);
		const authorizedForDriver: boolean = isUserLicensed(this.userAuthorization!, AuthorizationConstants.MOBILE_DRIVER_LICENSE);
		const authorizedForUserAdmin: boolean = isUserLicensed(this.userAuthorization!, AuthorizationConstants.AGENT_USER_MANAGEMENT_LICENSE);
		if (authorizedForLiveMap && !authorizedForDriver && !authorizedForUserAdmin) {
			this.router.navigate([this.menuItemsToShow.find((menuItem: MenuItem) => menuItem.Title == NavigationConstants.LIVE_MAP_NAME)!.Url]);
			return;
		}
		if (!authorizedForLiveMap && authorizedForDriver && !authorizedForUserAdmin && !authorizedForTimeToService) {
			this.router.navigate([this.menuItemsToShow.find((menuItem: MenuItem) => menuItem.Title == NavigationConstants.MOBILE_DRIVER_NAME)!.Url]);
			return;
		}
		if (!authorizedForLiveMap && !authorizedForDriver && authorizedForUserAdmin && !authorizedForTimeToService) {
			this.router.navigate([this.menuItemsToShow.find((menuItem: MenuItem) => menuItem.Title == NavigationConstants.AGENT_USER_MANAGEMENT_NAME)!.Url]);
			return;
		}
		if (!authorizedForLiveMap && !authorizedForDriver && !authorizedForUserAdmin && authorizedForTimeToService) {
			this.router.navigate([this.menuItemsToShow.find((menuItem: MenuItem) => menuItem.Title == NavigationConstants.TIME_TO_SERVICE_CONFIGURATION_NAME)!.Url]);
			return;
		}
		this.Loading = false;
	}
}
