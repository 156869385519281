/**
 * The state associated with the user.
 */
export class UserState {

	/**
	 * @constructor
	 *
	 * @param CrownIdentifier The Crown ID of the customer.
	 * @param MapDate The date for which the map is set for the user.
	 * @param Username The current user's username.
	 * @param FilterTelemetry The flag indicating if the user's telemetry should be filtered.
	 */
	constructor(
		public readonly CrownIdentifier: string | null,
		public readonly MapDate: Date | null,
		public readonly Username: string | null,
		public readonly FilterTelemetry: boolean | null
	) { }

}