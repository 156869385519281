import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FormBoxHeaderComponent } from './form-box-header/form-box-header.component';
import { FormBoxComponent } from './form-box/form-box.component';
import { FormSectionHeadingComponent } from './form-section-heading/form-section-heading.component';

/**
 * This module declares the reusable form box and related components for Crown X.
 */
@NgModule({
	declarations: [
		FormBoxComponent,
		FormBoxHeaderComponent,
		FormSectionHeadingComponent
	],
	imports: [
		CommonModule,
		FormsModule
	],
	exports: [
		FormBoxComponent,
		FormBoxHeaderComponent,
		FormSectionHeadingComponent
	]
})
export class FormBoxModule { }