import { Component, Input } from '@angular/core';

/**
 * Used to show the overlay shadow.
 */
@Component({
	selector: 'crownx-overlay-shadow',
	templateUrl: './overlay-shadow.component.html',
	styleUrls: ['./overlay-shadow.component.css']
})
export class OverlayShadowComponent {

	/**
	 * Indicates if the overlay shadow is visible
	 */
	@Input()
	public IsOverlayShadowVisible: boolean = false;

	/**
	 * The z-index amount for the overlay shadow
	 */
	@Input()
	public ZIndex: number = 300;

	/**
	 * The type of CSS position for the overlay shadow
	 */
	@Input()
	public Position: string = "fixed";
}
