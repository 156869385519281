import { HoverIntentDirective } from './hover-intent.directive';
import { NgModule } from '@angular/core';
import { CrownIdentifierInputDirective } from '../crown/crown-identifier-input.directive';
import { EmailInputDirective } from './email-input.directive';
import { PasswordInputDirective } from './password-input.directive';
import { NumberDirective } from './number.directive';
import { ClickStopPropagation } from './click-stop-propagation.directive';

/**
 * This module declares the reusable input directives.
 */
@NgModule({
	declarations: [
		CrownIdentifierInputDirective,
		EmailInputDirective,
		PasswordInputDirective,
		HoverIntentDirective,
		NumberDirective,
		ClickStopPropagation
	],
	exports: [
		CrownIdentifierInputDirective,
		EmailInputDirective,
		PasswordInputDirective,
		HoverIntentDirective,
		NumberDirective,
		ClickStopPropagation
	]
})
export class SharedInputModule { }
