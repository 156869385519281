import { Router } from '@angular/router';
import { HttpErrorResponse, HttpResponse, HttpResponseBase, HttpStatusCode } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { LoggerService } from './logger.service';
import { Injectable } from '@angular/core';

/**
 * Provides logging for HTTP requests. Modified from:
 * https://angular.io/guide/http#using-interceptors-for-logging
 * https://angular.io/guide/http#getting-error-details
 * https://rollbar.com/blog/error-handling-with-angular-8-tips-and-best-practices/
 */
@Injectable()
export class HttpRequestLoggingInterceptor implements HttpInterceptor {

	/**
	 * @constructor
	 *
	 * @param logger The logging service.
	 * @param router The router used to change views.
	 */
	constructor(
		private readonly logger: LoggerService,
		private readonly router: Router
	) { }

	/**
	 * The method that handles intercepting HTTP requests and logging start, stop, and error information.
	 *
	 * @param req The outgoing request object to handle.
	 * @param next The next interceptor in the chain, or the backend
	 * if no interceptors remain in the chain.
	 * @returns An observable of the event stream.
	 */
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const requestIdentifier: number = Math.floor(Math.random() * 1000)
		const requestStartTime: Date = new Date()
		this.logger.TrackHttpStart(request, requestIdentifier);
		let result: string;
		let response: HttpResponseBase;

		return next.handle(request)
			.pipe(
				tap((event: HttpEvent<any>) => {
					if (event instanceof HttpResponse) {
						result = 'success';
						response = event;
					}
				}),
				catchError((error: HttpErrorResponse) => {
					result = 'failed';
					response = error;
					let errorMessage = '';
					if (error.error instanceof ErrorEvent) {
						errorMessage = `Client side error. Message: ${error.error.message}`;
					} else {
						errorMessage = `Server side error. Code: ${error.status}\nMessage: ${error.message}\nBody: ${error.error}`;
					}
					this.logger.TrackError(`[HTTP] (id=${requestIdentifier}) Request failure. ${request.method} ${request.url} status=${error.status} errorMessage=${errorMessage}`, error);
					// Allow Error codes that are handled elsewhere to passthrough after logging.
					if (error.status == HttpStatusCode.Unauthorized
						|| error.status == HttpStatusCode.Forbidden) {
						return throwError(() => error);
					}
					return throwError(() => "Error executing HTTP request.");
				}),
				finalize(() => {
					this.logger.TrackHttpEnd(request, requestIdentifier, response, result, requestStartTime);
				}),
			);
	}
}

