import { Component, Input } from '@angular/core';

/**
 * Defines a notification badge to be displayed in notification areas in the Crown X ecosystem.
 */
@Component({
	selector: 'notification-badge',
	templateUrl: './notification-badge.component.html',
	styleUrls: ['./notification-badge.component.css']
})
export class NotificationBadgeComponent {
	/**
	 * The number of notifications available to the user, to be shown on the badge.
	 */
	@Input() public Count: number = 0;
}