import { HttpParameterCodec } from '@angular/common/http';

/**
 * Allows Http params to be encoded.
 */
export class CustomHttpParamEncoder implements HttpParameterCodec {

	/**
	 * Encodes the param key.
	 * @param key The key to encode.
	 * @returns The encoded key.
	 */
	public encodeKey(key: string): string {
		return encodeURIComponent(key);
	}

	/**
	 * Encodes the param value.
	 * @param value The value to encode.
	 * @returns The encoded value.
	 */
	public encodeValue(value: string): string {
		return encodeURIComponent(value);
	}

	/**
	 * Decodes the param key.
	 * @param key The key to decode.
	 * @returns The decoded key.
	 */
	public decodeKey(key: string): string {
		return decodeURIComponent(key);
	}

	/**
	 * Decodes the param value.
	 * @param value The value to decode.
	 * @returns The decoded value.
	 */
	public decodeValue(value: string): string {
		return decodeURIComponent(value);
	}
}