import { Component, OnDestroy, OnInit } from '@angular/core';
import { BeforeInstallPromptEvent } from 'src/app/models/before-install-prompt-event';
import { InstallPromptResult } from 'src/app/models/install-prompt-result';
import { LoggerService } from 'src/app/telemetry/logger.service';
import { TelemetryIdentifiers } from 'src/app/telemetry/telemetry-identifiers';
import { InstallPwaEventsConstants } from '../install-pwa-events-constants';

/**
 * A banner for displaying notification messages to users.
 */
@Component({
	selector: 'crown-install-prompt',
	templateUrl: './install-prompt.component.html',
	styleUrls: ['./install-prompt.component.css']
})
export class InstallPromptComponent implements OnInit, OnDestroy {
	/**
	 * Used to prompt users for installation of the PWA on their mobile device.
	 */
	private installPrompt: BeforeInstallPromptEvent | null = null;

	/**
	 * Gets or sets whether or not the banner is currently visible.
	 */
	public Visible: boolean = false;

	/**
	 * @constructor
	 *
	 * @param logger The logging service.
	 */
	constructor(
		private readonly logger: LoggerService,
	) {}

	/**
	 * Component initialization.
	 */
	public ngOnInit(): void {
		window.addEventListener(InstallPwaEventsConstants.APP_INSTALLED_EVENT, this.OnAppInstalled.bind(this));
		window.addEventListener(InstallPwaEventsConstants.BEFORE_INSTALL_PROMPT_EVENT, this.OnBeforeInstallPrompt.bind(this) as EventListener);
	}

	/**
	 * Component destruction.
	 */
	public ngOnDestroy(): void {
		window.removeEventListener(InstallPwaEventsConstants.APP_INSTALLED_EVENT, this.OnAppInstalled.bind(this));
		window.removeEventListener(InstallPwaEventsConstants.BEFORE_INSTALL_PROMPT_EVENT, this.OnBeforeInstallPrompt.bind(this) as EventListener);
	}

	/**
	 * Handles when the user clicks the "install" button on the install PWA container by triggering
	 * the installation prompt if the workflow to install the PWA is supported by the browser.
	 */
	public async OnInstallPwaButtonClick(): Promise<void> {
		if (!this.installPrompt)
			return;

		const result: InstallPromptResult = await this.installPrompt.prompt();
		this.logger.TrackEvent(TelemetryIdentifiers.EVENT_INSTALL_PWA_TRIGGERED, result);
		this.ClosePwaInstallPrompt();
	}

	/**
	 * Handles when the user clicks the "close" button on the install PWA container by dismissing
	 * the prompt and capturing telemetry.
	 */
	public OnInstallPwaCloseButtonClick(): void {
		this.ClosePwaInstallPrompt();
		this.logger.TrackEvent(TelemetryIdentifiers.EVENT_INSTALL_PWA_DISMISSED);
	}

	/**
	 * Handles when the browser detects that the PWA can be installed on the user's mobile device
	 * by preventing the default browser prompt and using the Crown-specific prompt.
	 */
	private OnBeforeInstallPrompt(prompt: BeforeInstallPromptEvent): void {
		prompt.preventDefault();
		this.installPrompt = prompt;
		this.Visible = true;
	}

	/**
	 * Handles when the browser detects that the PWA has been installed by capturing
	 * telemetry.
	 */
	private OnAppInstalled(): void {
		this.logger.TrackEvent(TelemetryIdentifiers.EVENT_INSTALL_PWA_COMPLETE);
	}

	/**
	 * Closes the prompt for installing the PWA on the user's mobile device.
	 */
	private ClosePwaInstallPrompt(): void {
		this.installPrompt = null;
		this.Visible = false;
	}
}