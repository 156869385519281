import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Authorization } from 'src/app/authorization/authorization';
import { AuthorizationService } from 'src/app/authorization/authorization.service';
import { isUserLicensed } from 'src/app/common/common-functions';
import { MenuItem } from '../menu-item';
import { LoggerService } from 'src/app/telemetry/logger.service';
import { UserStateService } from 'src/app/user-state/user-state.service';
import { NavigationEnd, Router } from '@angular/router';
import { LogoutSwitchService } from '../logout-switch.service';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { NavigationConstants } from 'src/app/common/navigation-constants';

/**
 * This component displays the menu.
 */
@Component({
	selector: 'crown-x-menu-toolbar',
	templateUrl: './menu-toolbar.component.html',
	styleUrls: ['./menu-toolbar.component.css']
})
export class MenuToolbarComponent implements OnInit, OnDestroy {

	/** The list of menus that the user can see. */
	public MenuItemsToShow: MenuItem[] = [];

	/** Indicates if the hamburger menu is open. */
	public IsHamburgerMenuOpen: boolean = false;

	/** The name of the currently loaded company. */
	public CompanyName: string = "";

	/** Indicates whether the user has authorization for more than one Crown ID. */
	public HasMultipleCrownIds: boolean = false;

	/** A reference to the navigation menu. */
	@ViewChild("NavigationMenu")
	public NavigationMenu!: ElementRef;

	/** A reference to the hamburger menu icon. */
	@ViewChild("HamburgerMenuIcon")
	public HamburgerMenuIcon!: ElementRef;

	/** The user's roles. */
	private userRoles: string[] = [];

	/** The user's authorization. */
	private userAuthorization: Authorization | null = null;

	/** Used to unsubscribe from observables. */
	private unsubscriber = new Subject();

	/**
	 * @constructor
	 *
	 * @param authorizationService The authorization service.
	 * @param logger The logging service.
	 * @param userStateService The service used to change and get the user state.
	 * @param router The Angular router service.
	 * @param logoutSwitchService The service used to logout and switch the current company.
	 * @param authenticationService The service that checks authentication.
	 */
	constructor(
		private readonly authorizationService: AuthorizationService,
		private readonly logger: LoggerService,
		private readonly userStateService: UserStateService,
		private readonly router: Router,
		private readonly logoutSwitchService: LogoutSwitchService,
		private readonly authenticationService: AuthenticationService
	) {
		this.router.events.pipe(takeUntil(this.unsubscriber)).subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.IsHamburgerMenuOpen = false;
			}
		})
	}

	/**
	 * Component initialization.
	 */
	public ngOnInit(): void {
		this.UpdateCompanyInfo();
		this.userStateService.OnCrownIdentifierChanged$()
			.pipe(takeUntil(this.unsubscriber))
			.subscribe(() =>
				this.UpdateCompanyInfo()
			);
		this.authorizationService.OnGetAuthorization$()
			.pipe(takeUntil(this.unsubscriber))
			.subscribe((auth: Authorization | null) => {
				this.userRoles = auth?.Roles ?? [];
				this.userAuthorization = auth;
				this.PopulateMenuItems();
			});
	}

	/**
	 * Component desconstruction.
	 */
	public ngOnDestroy(): void {
		this.unsubscriber.next(null);
		this.unsubscriber.complete();
	}

	/**
	 * Updates the info for the currently loaded company.
	 */
	public UpdateCompanyInfo(): void {
		this.authorizationService.GetAuthorization$()
			.pipe(takeUntil(this.unsubscriber))
			.subscribe((auth: Authorization) => {
				const companyName: string | undefined = auth.AuthorizedAgents.find(agent =>
					agent.CrownIdentifier === this.CrownId
				)?.CompanyName;
				if (companyName == null) {
					this.CompanyName = "";
				}
				else {
					this.CompanyName = companyName;
				}

				this.HasMultipleCrownIds = auth.AuthorizedAgents.length > 1;
			});
	}

	/**
	 * Gets the currently loaded Crown ID.
	 */
	public get CrownId(): string | null {
		return this.userStateService.State.CrownIdentifier;
	}

	/**
	 * The user-name of the current user.
	 */
	public get UserName(): string | null {
		return this.authenticationService.UserName;
	}

	/**
	 * Event handler for clicks to so that menu closes.
	 * @param event The event associated with the click
	 */
	@HostListener('document:mousedown', ['$event'])
	public OnGlobalClick(event: Event): void {
		if (!this.NavigationMenu.nativeElement.contains(event.target) && !this.HamburgerMenuIcon.nativeElement.contains(event.target))
			this.IsHamburgerMenuOpen = false;
	}

	/**
	 * Populate menu items based on the user's authorizations.
	 */
	public PopulateMenuItems(): void {
		this.MenuItemsToShow = [];
		const agentSelected = Boolean(this.userStateService.State?.CrownIdentifier);
		NavigationConstants.NAVIGATION_MENU_ITEMS.forEach((item: MenuItem) => {
			if (item.Role != null && this.userRoles.filter((role: string) => role == item.Role).length < 1)
				return;

			if (item.License != null && this.userAuthorization != null && !isUserLicensed(this.userAuthorization, item.License))
				return;

			if (item.AgentRequiredToShow && !agentSelected)
				return;

			this.MenuItemsToShow.push(item);
		});
	}

	/**
	 * Tracks when a menu item is clicked and logs an event.
	 * @param telemetryIdentifier The telemetry identifier of the menu item that was clicked.
	 */
	public TrackEvent(telemetryIdentifier: string | null): void {
		if (telemetryIdentifier && !this.userStateService.State.FilterTelemetry) {
			this.logger.TrackEvent(telemetryIdentifier, { CrownIdentifier: this.userStateService.State.CrownIdentifier, UserName: this.userStateService.State.Username});
		}
	}

	/**
	 * Toggles the hamburger menu open and closed.
	 */
	public ToggleMenu(): void {
		this.IsHamburgerMenuOpen = !this.IsHamburgerMenuOpen;
	}

	/**
	 * Changes the company whose data is currently being viewed.
	 */
	public ChangeCompany(): void {
		this.logoutSwitchService.ChangeCompany();
	}

	/**
	 * Handler for when logout button is clicked
	 */
	public Logout(): void {
		this.logoutSwitchService.Logout();
	}
}