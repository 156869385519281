import { ErrorHandler, Injectable } from '@angular/core';
import { LoggerService } from './logger.service';

/**
 * The global error handler to catch uncaught exceptions.
 */
@Injectable()
export class GlobalErrorHandlerService extends ErrorHandler {

	/**
	 * @constructor
	 *
	 * @param logger The logging service.
	 */
	constructor(
		private logger: LoggerService
	) {
		super();
	}

	/**
	 * This method is called whenever there is an uncaught exception thrown.
	 *
	 * @param error The uncaught exception.
	 */
	public handleError(error: Error): void {
		this.logger.TrackError(`Error caught in global error handler.`, error);
	}

}