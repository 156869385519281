import { UserSessionState } from './user-session-state';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { LoggerService } from '../telemetry/logger.service';

/**
 * This service is used to access user session data.
 */
@Injectable({
	providedIn: 'root'
})
export class UserSessionDataService {

	/** The path to the agent controller. */
	private static readonly CONTROLLER_PATH: string = "/api/usersessions";

	/**
	 * @constructor
	 *
	 * @param httpClient The HTTP client to use for web requests.
	 * @param logger The logging service.
	 */
	 constructor(
		private readonly httpClient: HttpClient,
		private readonly logger: LoggerService
	) { }

	/**
	 * Updates the last active datetime on a user's session.
	 * @returns A void observable.
	 */
	 public UpdateLastActive$(): Observable<void> {
		try {
			const url: string = `${UserSessionDataService.CONTROLLER_PATH}/lastactive`;
			return this.httpClient.patch<void>(url, null, { withCredentials: true });
		}
		catch (error: any) {
			this.logger.TrackError("Unable to build user session request.", error);
			return throwError(error);
		}
	}

	/**
	 * Gets a user session state.
	 * @returns An observable with the user session state.
	 */
	 public GetState$(): Observable<UserSessionState> {
		try {
			const url: string = UserSessionDataService.CONTROLLER_PATH;
			return this.httpClient.get<UserSessionState>(url, { withCredentials: true });
		}
		catch (error: any) {
			this.logger.TrackError("Unable to build user session request.", error);
			return throwError(error);
		}
	}
}
