import { AuthorizationConstants } from "../authorization/authorization-constants";
import { MenuItem } from "../layout/menu-item";
import { TelemetryIdentifiers } from "../telemetry/telemetry-identifiers";

/**
 * Constants for shared navigation logic.
 */
export class NavigationConstants {

	/**
	 * The name of the live map link.
	 */
	public static readonly LIVE_MAP_NAME: string = "Live Map";

	/**
	 * The name of the send diagnostics data link.
	 */
	public static readonly SEND_DIAGNOSTICS_DATA_NAME: string = "Send Diagnostics Data";

	/**
	 * The name of the time to service configuration link.
	 */
	public static readonly TIME_TO_SERVICE_CONFIGURATION_NAME: string = "Time To Service Configuration";

	/**
	 * The name of the mobile driver link.
	 */
	public static readonly MOBILE_DRIVER_NAME: string = "CrownX Driver";

	/**
	 * The name of the crown user management link.
	 */
	public static readonly CROWN_USER_MANAGEMENT_NAME: string = "Crown User Management";

	/**
	 * The name of the user roles management link.
	 */
	public static readonly USER_ROLES_MANAGEMENT_NAME: string = "User Roles Management";

	/**
	 * The name of the agent management link.
	 */
	public static readonly AGENT_MANAGEMENT_NAME: string = "Agent Management";

	/**
	 * The name of the agent user management link.
	 */
	public static readonly AGENT_USER_MANAGEMENT_NAME: string = "User Management";

	/**
	 * The name of the picture slot configuration link.
	 */
	public static readonly PICTURE_SLOT_CONFIGURATION_NAME: string = "Picture Slot Configuration";

	/**
	 * The name of the survey link.
	 */
	public static readonly TAKE_SURVEY_NAME: string = "Take Survey";

	/**
	 * The name of the help link.
	 */
	public static readonly HELP_NAME: string = "Help";

	/**
	 * The list of menu navigation links available.
	 */
	public static readonly NAVIGATION_MENU_ITEMS: MenuItem[] = [
		{
			Title: NavigationConstants.LIVE_MAP_NAME,
			Url: "/maps/dispatch-map",
			AgentRequiredToShow: true,
			Role: null,
			License: AuthorizationConstants.LIVE_MAP_LICENSE,
			OpenInNewTab: false,
			TelemetryIdentifier: TelemetryIdentifiers.EVENT_OPEN_LIVE_MAP_FROM_MENU,
			ShowOnFeaturesLanding: true
		},
		{
			Title: NavigationConstants.TIME_TO_SERVICE_CONFIGURATION_NAME,
			Url: "/maps/time-to-service",
			AgentRequiredToShow: true,
			Role: null,
			License: AuthorizationConstants.ROUTE_PLANNING_LICENSE,
			OpenInNewTab: false,
			TelemetryIdentifier: TelemetryIdentifiers.EVENT_OPEN_TIME_TO_SERVICE_CONFIGURATION_FROM_MENU,
			ShowOnFeaturesLanding: true
		},
		{
			Title: NavigationConstants.MOBILE_DRIVER_NAME,
			Url: "/driver",
			AgentRequiredToShow: true,
			Role: null,
			License: AuthorizationConstants.MOBILE_DRIVER_LICENSE,
			OpenInNewTab: false,
			TelemetryIdentifier: TelemetryIdentifiers.EVENT_OPEN_CROWN_X_DRIVER_FROM_MENU,
			ShowOnFeaturesLanding: true
		},
		{
			Title: NavigationConstants.CROWN_USER_MANAGEMENT_NAME,
			Url: "/crown/user-management",
			AgentRequiredToShow: false,
			Role: AuthorizationConstants.CROWN_USER_MANAGEMENT_ROLE,
			License: null,
			OpenInNewTab: false,
			TelemetryIdentifier: null,
			ShowOnFeaturesLanding: false
		},
		{
			Title: NavigationConstants.USER_ROLES_MANAGEMENT_NAME,
			Url: "/crown/user-management/roles",
			AgentRequiredToShow: false,
			Role: AuthorizationConstants.USER_ROLE_MANAGEMENT_ROLE,
			License: null,
			OpenInNewTab: false,
			TelemetryIdentifier: null,
			ShowOnFeaturesLanding: false
		},
		{
			Title: NavigationConstants.AGENT_MANAGEMENT_NAME,
			Url: "/crown/agent-management",
			AgentRequiredToShow: false,
			Role: AuthorizationConstants.AGENT_MANAGEMENT_ROLE,
			License: null,
			OpenInNewTab: false,
			TelemetryIdentifier: null,
			ShowOnFeaturesLanding: false
		},
		{
			Title: NavigationConstants.AGENT_USER_MANAGEMENT_NAME,
			Url: "/user-management",
			AgentRequiredToShow: true,
			Role: null,
			License: AuthorizationConstants.AGENT_USER_MANAGEMENT_LICENSE,
			OpenInNewTab: false,
			TelemetryIdentifier: TelemetryIdentifiers.EVENT_OPEN_USER_MANAGEMENT_FROM_MENU,
			ShowOnFeaturesLanding: true
		},
		{
			Title: NavigationConstants.PICTURE_SLOT_CONFIGURATION_NAME,
			Url: "/agent-configuration/picture-configuration",
			AgentRequiredToShow: true,
			Role: null,
			License: AuthorizationConstants.PICTURE_SLOT_CONFIGURATION_LICENSE,
			OpenInNewTab: false,
			TelemetryIdentifier: TelemetryIdentifiers.EVENT_OPEN_PICTURE_SLOT_CONFIGURATION_FROM_MENU,
			ShowOnFeaturesLanding: true
		},
		{
			Title: NavigationConstants.TAKE_SURVEY_NAME,
			Url: "https://www.surveymonkey.com/r/TXHVW9S",
			AgentRequiredToShow: false,
			Role: null,
			License: null,
			OpenInNewTab: true,
			TelemetryIdentifier: TelemetryIdentifiers.EVENT_OPEN_SURVEY_FROM_MENU,
			ShowOnFeaturesLanding: false
		},
		{
			Title: NavigationConstants.SEND_DIAGNOSTICS_DATA_NAME,
			Url: "/diagnostics-data",
			AgentRequiredToShow: true,
			Role: null,
			License: null,
			OpenInNewTab: false,
			TelemetryIdentifier: TelemetryIdentifiers.EVENT_SEND_DIAGNOSTICS_DATA_MENU,
			ShowOnFeaturesLanding: false
		},
		{
			Title: NavigationConstants.HELP_NAME,
			Url: "/help",
			AgentRequiredToShow: false,
			Role: null,
			License: AuthorizationConstants.LIVE_MAP_LICENSE,
			OpenInNewTab: true,
			TelemetryIdentifier: TelemetryIdentifiers.EVENT_OPEN_HELP_FROM_MENU,
			ShowOnFeaturesLanding: false
		}
	];
}