import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { PageToolbarActionButton } from './page-toolbar/page-toolbar-action-button';

/**
 * Service for the page's toolbar
 */
@Injectable({
	providedIn: 'root'
})
export class PageToolbarService {
	/** The current page's navigation buttons. */
	private pageButtons: PageToolbarActionButton[] = [];

	/**
	 * Sets the current page's buttons in the toolbar.
	 * @param value The array of NavButtons to update as the page buttons
	 */
	public set PageButtons(value: PageToolbarActionButton[]) {
		this.pageButtons = value;
	}

	/**
	 * Gets the current page buttons on the toolbar.
	 */
	public get PageButtons(): PageToolbarActionButton[] {
		return this.pageButtons;
	}

	/**
	 * Pushes additional buttons into the page's navigation buttons list.
	 * @param buttons The NavButtons to push into the page buttons list.
	 */
	public AddToExistingPageButtons(buttons: PageToolbarActionButton[]): void {
		buttons.forEach(button => this.pageButtons.push(button));
	}

	/**
	 * Disables buttons where the title matches the list provided.
	 * @param buttonTitles A list of titles of the buttons to be disabled.
	 */
	public DisableButtons(buttonTitles: string[]): void {
		this.pageButtons.forEach(button => {
			const buttonShouldBeDisabled = buttonTitles.filter(title => button.Title == title).length >= 1;
			if (buttonShouldBeDisabled) {
				button.IsDisabled = true;
			}
		});
	}

	/**
	 * Enables buttons where the title matches the list provided.
	 * @param buttonTitles A list of titles of the buttons to be enabled.
	 */
	public EnableButtons(buttonTitles: string[]): void {
		this.pageButtons.forEach(button => {
			const buttonShouldBeEnabled = buttonTitles.filter(title => button.Title == title).length >= 1;
			if (buttonShouldBeEnabled) {
				button.IsDisabled = false;
			}
		});
	}
}
