import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { Authentication } from "./authentication";

/**
 * This guard only allows access to pages if the user is authenticated; otherwise it redirects the user to the login page.
 */
@Injectable()
export class AuthenticationGuard  {

	/**
	 * @constructor
	 *
	 * @param router The router used to change views.
	 * @param authenticationService The authentication service.
	 */
	constructor(
		private readonly router: Router,
		private readonly authenticationService: AuthenticationService
	) { }

	/**
	 * Overridden method indicating if a route can be activated.
	 */
	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		return this.CheckAuthenticated$();
	}

	/**
	 * Overridden method indicating if a child route can be loaded.
	 */
	public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.CheckAuthenticated$();
	}

	/**
	 * Creates an observable that checks if the current session is authenticated and redirects to the login page if it's not.
	 *
	 * @returns An observable that returns whether a route is authenticated.
	 */
	private CheckAuthenticated$(): Observable<boolean> {
		return this.authenticationService.GetAuthentication$()
				.pipe(
					map((user: Authentication) => {
						if (!user.IsAuthenticated) {
							this.router.navigate(["/login"]);
						}
						return user.IsAuthenticated;
					}),
					catchError(err => {
						this.router.navigate(["/login"]);
						return of(false)
					}),
				);
	}

}