<div [class.visible-dialog]="IsVisible" class="dialog">
	<div *ngIf="DialogTitle" class="dialog-title crown-blue-background">{{ DialogTitle }}</div>
	<div class="dialog-padding">
		<ng-content></ng-content>
	</div>
	<div *ngIf="ShowDefaultButtons" class="dialog-buttons">
		<button class="dialog-cancel-button" (click)="OnCancelButtonClick()">{{ CancelText }}</button>
		<button type="submit" class="crown-blue-button saving-feedback-button" (click)="OnOkButtonClick()" [disabled]="!IsFormValid || IsOkLoading">
			<span>{{ IsOkLoading ? OkLoadingText : OkText }}</span>
			<div *ngIf="IsOkLoading" class="loading-spinner"></div>
		</button>
	</div>
</div>