import { Component, OnDestroy, OnInit } from "@angular/core";
import { NetworkConnectivityService } from "../../network-connectivity.service";
import { Subject, takeUntil } from "rxjs";

/**
 * This component displays the network connectivity icon.
 */
@Component({
	selector: 'crown-x-network-connectivity',
	templateUrl: './network-connectivity.component.html',
	styleUrls: ['./network-connectivity.component.css']
})
export class NetworkConnectivityComponent implements OnInit, OnDestroy {

	/**
	 * Status if there is currently an internet connection
	 */
	public IsOnline: boolean = true;

	/**
	 *  Used to unsubscribe from observables.
	 */
	private unsubscriber = new Subject();

	/**
	 * @constructor
	 *
	 * @param networkConnectivityService The service used to get the current network connnectivity.
	 */
	constructor(
		private networkConnectivityService: NetworkConnectivityService
	) { }

	/**
	 * Component initialization.
	 */
	public ngOnInit(): void {
		this.IsOnline = navigator.onLine;
		this.networkConnectivityService.GetOnlineObserver$().pipe(takeUntil(this.unsubscriber)).subscribe((online: boolean) => {
			this.IsOnline = online;
		});
	}

	/**
	 * Component desconstruction.
	 */
	public ngOnDestroy(): void {
		this.unsubscriber.next(null);
		this.unsubscriber.complete();
	}
}