import { LogoutSwitchService } from './../logout-switch.service';
import { Router } from '@angular/router';
import { AuthenticationService } from './../../authentication/authentication.service';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TitleService } from '../title.service';
import { AuthorizationService } from '../../authorization/authorization.service';
import { UserStateService } from '../../user-state/user-state.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Authorization } from '../../authorization/authorization';

/**
 * This component is the title/top bar in the application.
 */
@Component({
	selector: 'crownx-title-bar',
	templateUrl: './title-bar.component.html',
	styleUrls: ['./title-bar.component.css']
})
export class TitleBarComponent implements OnInit, OnDestroy {

	/** The name of the currently loaded company. */
	public CompanyName: string = "";

	/** Indicates whether the user has authorization for more than one Crown ID. */
	public HasMultipleCrownIds: boolean = false;

	/** Indicates whether the user menu is open. */
	public UserMenuOpen: boolean = false;

	/** A reference to the dropdown for dispatch. */
	@ViewChild("UserMenuDropdown")
	public UserMenuDropdownElement!: ElementRef;

	/** Used to unsubscribe from observables. */
	private unsubscriber = new Subject();

	/**
	 * @constructor
	 *
	 * @param titleService The service that update the page's title.
	 * @param authenticationService The service that checks authentication.
	 * @param authorizationService The service that checks authorization.
	 * @param userStateService The service that handles user state.
	 * @param logoutSwitchService The service used to logout and switch the current company.
	 */
	constructor(
		private readonly titleService: TitleService,
		private readonly authenticationService: AuthenticationService,
		private readonly authorizationService: AuthorizationService,
		private readonly userStateService: UserStateService,
		private readonly logoutSwitchService: LogoutSwitchService
	) { }

	/**
	 * Component initialization.
	 */
	public ngOnInit(): void {
		this.OnUserMenuDropDown(false);
		this.UpdateCompanyInfo();

		this.userStateService.OnCrownIdentifierChanged$()
			.pipe(takeUntil(this.unsubscriber))
			.subscribe(() =>
				this.UpdateCompanyInfo()
			);
	}

	/**
	 * Component destruction.
	 */
	public ngOnDestroy(): void {
		this.unsubscriber.next(null);
		this.unsubscriber.complete();
	}

	/**
	 * Event handler for clicks outside of dropdown
	 * @param event The event associated with the click
	 */
	@HostListener('document:mousedown', ['$event'])
	public OnGlobalClick(event: Event): void {
		if (this.UserMenuOpen && !this.UserMenuDropdownElement.nativeElement.contains(event.target)) {
			this.OnUserMenuDropDown(false);
		}
	}

	/**
	 * Updates the info for the currently loaded company.
	 */
	public UpdateCompanyInfo(): void {
		if (this.authenticationService.IsAuthenticated) {
			this.authorizationService.GetAuthorization$()
				.pipe(takeUntil(this.unsubscriber))
				.subscribe((auth: Authorization) => {
					const companyName: string | undefined = auth.AuthorizedAgents.find(agent =>
						agent.CrownIdentifier === this.CrownId
					)?.CompanyName;
					if (companyName == null) {
						this.CompanyName = "";
					}
					else {
						this.CompanyName = companyName;
					}

					this.HasMultipleCrownIds = auth.AuthorizedAgents.length > 1;
				});
		}
	}

	/**
	 * The title of the current view.
	 */
	public get Title(): string | null {
		return this.titleService.Title;
	}

	/**
	 * The user-name of the current user.
	 */
	public get UserName(): string | null {
		return this.authenticationService.UserName;
	}

	/**
	 * Indicates if a user currently is authenticated.
	 */
	public get IsAuthenticated(): boolean {
		return this.authenticationService.IsAuthenticated;
	}

	/**
	 * Gets the currently loaded Crown ID.
	 */
	public get CrownId(): string | null {
		return this.userStateService.State.CrownIdentifier;
	}

	/**
	 * Event handler for when the user menu dropdown button is clicked to expand or collapse
	 * @param isDroppedDown Indicates if the user menu should be open.
	 */
	public OnUserMenuDropDown(isDroppedDown: boolean | null = null): void {
		if (isDroppedDown === null) {
			this.UserMenuOpen = !this.UserMenuOpen;
		}
		else {
			this.UserMenuOpen = isDroppedDown;
		}
	}

	/**
	 * Changes the company whose data is currently being viewed.
	 */
	public ChangeCompany(): void {
		this.OnUserMenuDropDown(false);
		this.logoutSwitchService.ChangeCompany();
	}

	/**
	 * Handler for when logout button is clicked
	 */
	public Logout(): void {
		this.OnUserMenuDropDown(false);
		this.logoutSwitchService.Logout();
	}

}