import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { LoggerService } from '../telemetry/logger.service';
import { Authentication } from './authentication';
import { TelemetryIdentifiers } from '../telemetry/telemetry-identifiers';
import { UserStateService } from '../user-state/user-state.service';

/**
 * This service is used to authenticate to the web service.
 */
@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {

	/** The path to the authentication controller. */
	private static readonly AUTHENTICATION_CONTROLLER_PATH: string = "/api/Authentication";

	/** Holds the current user's information. */
	private authInfo: Authentication | null = null;

	/**
	 * @constructor
	 *
	 * @param httpClient The HTTP client to use for web requests.
	 * @param logger The logging service.
	 * @param userStateService The user state service.
	 */
	constructor(
		private readonly httpClient: HttpClient,
		private readonly logger: LoggerService,
		private readonly userStateService: UserStateService
	) { }

	/**
	 * Indicates if the current session is authenticated.
	 *
	 * @readonly
	 */
	public get IsAuthenticated(): boolean
	{
		return (this.authInfo == null ? false : this.authInfo.IsAuthenticated);
	}

	/**
	 * The user-name of the current user or null if the user isn't authenticated.
	 *
	 * @readonly
	 */
	public get UserName(): string | null {
		if (this.authInfo == null)
			return null;
		else
			return this.authInfo.UserName;
	}

	/**
	 * The email of the current user or null if the user isn't authenticated.
	 *
	 * @readonly
	 */
	public get UserEmail(): string | null {
		if (this.authInfo == null)
			return null;
		else
			return this.authInfo.Email;
	}

	/**
	 * Passes credentials to the web service for validation.
	 *
	 * @param userName The user name supplied for authentication.
	 * @param password The password supplied for authentication.
	 * @returns An observatble containing user's Authorization information.
	 */
	public Login$(userName: string, password: string): Observable<Authentication>
	{
		try {
			const credentials = {
				UserName: userName,
				Password: password
			};

			// Build the request.
			const url: string = AuthenticationService.AUTHENTICATION_CONTROLLER_PATH;
			const observable: Observable<Authentication> = this.httpClient.post<Authentication>(url, credentials, { withCredentials: true })
				.pipe(tap(
					(authInfo: Authentication) => {
						this.logger.TrackEvent(TelemetryIdentifiers.EVENT_LOGIN, { UserName: userName });
						this.authInfo = authInfo;
					}
				));

			return observable;
		}
		catch (error: any) {
			this.logger.TrackError(`Error building Login request.`, error);
			return throwError(() => error);
		}

	}

	/**
	 * Gets the current user information based on the authentication cookie.
	 *
	 * @param force Indicates if the data should be reloaded if it's cached.
	 * @returns An observable containing user's Authorization information.
	 */
	public GetAuthentication$(force: boolean = false): Observable<Authentication> {

		if (this.authInfo == null || force) {

			try {
				// Build the request.
				const url: string = AuthenticationService.AUTHENTICATION_CONTROLLER_PATH;
				const observable: Observable<Authentication> = this.httpClient.get<Authentication>(url, { withCredentials: true })
					.pipe(tap(
						(authInfo: Authentication) => {
							this.authInfo = authInfo;
						}
					));

				return observable;
			}
			catch (error: any) {
				this.logger.TrackError(`Error building GetUser request.`, error);
				return throwError(() => error);
			}
		}
		else
		{
			return of(this.authInfo);
		}
	}

	/**
	 * Logs the current user out.
	 *
	 * @returns A void Observable.
	 */
	public Logout$(): Observable<void> {

		if (!this.userStateService.State.FilterTelemetry)
			this.logger.TrackEvent(TelemetryIdentifiers.EVENT_LOGOUT, { CrownIdentifier: this.userStateService.State.CrownIdentifier, UserName: this.userStateService.State.Username });

		this.ClearState();

		try {
			// Build the request.
			const url: string = AuthenticationService.AUTHENTICATION_CONTROLLER_PATH;
			return this.httpClient.delete<void>(url, { withCredentials: true });
		}
		catch (error: any) {
			this.logger.TrackError(`Error building Logout request.`, error);
			return throwError(() => error);
		}
	}

	/**
	 * Clears the current user authentication state.
	 */
	public ClearState(): void {
		this.userStateService.ClearState();
		this.authInfo = null;
	}

}