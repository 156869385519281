/**
 * Marker styles that correspond to those in EsriMaps.
 */
export type StopIconMarkerStyle = "circle" | "square" | "diamond" | "triangle" | "path" | undefined;

/**
 * Enum that corresponds to the Marker Styles in Esri.
 * Used to correlate StopIconMarkerStyle without literal strings.
 */
export enum IconMarkerStyle {
	Circle = "circle",
	Square = "square",
	Triangle = "triangle",
	Diamond = "diamond",
	Path = "path"
}

/**
 * Information used to Render a Stop.
 */
export class StopIcon {

	/** The Esri  MarkerStyle */
	MarkerStyle: StopIconMarkerStyle;

	/** Marker shape's color. */
	BackColor: string = "white";

	/** Color of the text that goes on the marker shape. */
	ForeColor: string = "black";
}