import { Toast } from './../toast';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastService } from '../toast.service';
import { Subscription } from 'rxjs';
import { ToastType } from '../toast-type.enum';
import { NavigationEnd, Router } from '@angular/router';

/**
 * This component shows toast messages.
 */
@Component({
  selector: 'crown-x-toast-list',
  templateUrl: './toast-list.component.html',
  styleUrls: ['./toast-list.component.css']
})
export class ToastListComponent implements OnInit, OnDestroy {

	/** The list of current toast messages to show on screen. */
	public ToastMessages: Toast[] = [];

	/**
	 * Gets the ToastType enum to use in the UI
	 */
	public get ToastType() {
		return ToastType;
	 }

	/** The toast message service subscription */
	private toastSubscription: Subscription | null = null;

	/**
	 * @constructor
	 *
	 * @param toastService The toast service.
	 * @param router The Angular router.
	 */
	constructor(
		private toastService: ToastService,
		private readonly router: Router
	) {
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.ToastMessages = [];
			}
		})
	}

	/**
	 * Component initialization.
	 */
	public ngOnInit(): void {
		this.toastSubscription = this.toastService.OnToastMessage().subscribe(
			(toast: Toast) => {
				this.AddNewToastMessage(toast);
			}
		);
	}

	/**
	 * Dynamically sets the background color based on the toast type and fade.
	 */
	public CalculateClasses(toast: Toast): string {
		const classes: string[] = [];
		switch (toast.Type) {
			case ToastType.Info:
				classes.push("background-info");
				break;
			case ToastType.Error:
				classes.push("background-error");
				break;
			default:
				classes.push("background-success");
				break;
		}

		if (toast.Fade) {
			classes.push("fade-out");
		}

		return classes.join(' ');
	}

	/**
	 * Removes and fades a toast message from the list.
	 */
	public Close(toast: Toast): void {
		const message = this.ToastMessages.find(message => message.Id === toast.Id)
		if (message) {
			message.Fade = true;
		}

		// remove toast after faded out
		setTimeout(() => {
			this.ToastMessages = this.ToastMessages.filter(toast => toast !== message);
		}, 1000)
	}

	/**
	 * Component deconstruction.
	 */
	public ngOnDestroy(): void {
		this.toastSubscription?.unsubscribe();
	}

	/**
	 * Performs the action on the toast and optionally closes the toast.
	 * @param toast The toast message for which to perform the action.
	 */
	public PerformToastAction(toast: Toast): void {
		if (toast.Action != null) {
			toast.Action();
		}
		if (toast.ActionShouldCloseToast) {
			this.Close(toast);
		}
	}

	/**
	 * Adds a toast message to the list to show on the screen.
	 * @param toast The toast message to add.
	 */
	private AddNewToastMessage(toast: Toast): void {
		if (!this.ToastMessages.find(message => message.Message == toast.Message)) {
			this.ToastMessages.push(toast);
			if (!toast.IsManualClose) {
				// auto close toast
				setTimeout(() => this.Close(toast), toast.VisibleTimeInMilliseconds || 5000);
			}
		}
	}
}
