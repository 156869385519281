import { Observable } from 'rxjs';
import { PageToolbarService } from './../page-toolbar.service';
import { PageToolbarActionButton } from './page-toolbar-action-button';
import { Component } from '@angular/core';

/**
 * Component used to display the page's toolbar.
 */
@Component({
	selector: 'crown-x-page-toolbar',
	templateUrl: './page-toolbar.component.html',
	styleUrls: ['./page-toolbar.component.css']
})
export class PageToolbarComponent {

	/**
	 * Used to display the buttons for the page.
	 */
	public get PageButtons(): PageToolbarActionButton[] {
		return this.pageToolbarService.PageButtons;
	}

	/**
	 * @constructor
	 * @param pageToolbarService The page toolbar service.
	 */
	constructor(private readonly pageToolbarService: PageToolbarService) { }
}
