import { ToastOptions } from './toast-options';
import { UUID } from 'angular2-uuid';
import { Toast } from './toast';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { ToastType } from './toast-type.enum';

/**
 * This service is used to interact with toast messages.
 */
@Injectable({
	providedIn: 'root'
})
export class ToastService {

	/** Used to trigger an update of toast messages. */
	private toastMessageEmmitter = new EventEmitter<Toast>();

	/** Adds an Error type toast message.
	 * @param message The toast message to be shown.
	 * @param options Options for the toast message.
	*/
	public ShowError(message: string, options: ToastOptions | null = null): void {
		const latestToast: Toast = { Id: UUID.UUID(), Message: message, Type: ToastType.Error, Fade: false, IsManualClose: options?.IsManualClose || false, ActionTitle: options?.ActionTitle || null, Action: options?.Action || null, ActionShouldCloseToast: options?.ActionShouldCloseToast || false, VisibleTimeInMilliseconds: options?.VisibleTimeInMilliseconds || null };
		this.toastMessageEmmitter.emit(latestToast);
	}

	/** Adds an Info type toast message.
	 * @param message The toast message to be shown.
	 * @param options Options for the toast message.
	*/
	public ShowInfo(message: string, options: ToastOptions | null = null): void {
		const latestToast: Toast = { Id: UUID.UUID(), Message: message, Type: ToastType.Info, Fade: false, IsManualClose: options?.IsManualClose || false, ActionTitle: options?.ActionTitle || null, Action: options?.Action || null, ActionShouldCloseToast: options?.ActionShouldCloseToast || false, VisibleTimeInMilliseconds: options?.VisibleTimeInMilliseconds || null };
		this.toastMessageEmmitter.emit(latestToast);
	}

	/** Signals that a toast message has been added. */
	public OnToastMessage(): Observable<Toast> {
		return this.toastMessageEmmitter.asObservable();
	}
}
