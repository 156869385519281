import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from '../authorization/authorization.service';

/**
 * Service used to navigate to logout or switch the current company
 */
@Injectable({
	providedIn: 'root'
})
export class LogoutSwitchService {

	/**
	 * @constructor
	 * @param router The router used to change views.
	 * @param authorizationService The service that checks authorization.
	 */
	constructor(
		private readonly router: Router,
		private readonly authorizationService: AuthorizationService
	) { }

	/**
	 * Navigates to logout.
	 */
	public Logout(): void {
		this.router.navigate(["/logout"]);
	}

	/**
	 * Navigates to changes the company whose data is currently being viewed.
	 */
	public ChangeCompany(): void {
		this.authorizationService.PreAuthorizationPageUrl = this.router.url;
		this.router.navigate(["/authorize"]);
	}
}
