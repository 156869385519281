import { HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent, HttpInterceptor, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TelemetryIdentifiers } from '../telemetry/telemetry-identifiers';
import { LoggerService } from '../telemetry/logger.service';
import { UserStateService } from '../user-state/user-state.service';
import { AuthenticationService } from './authentication.service';

/**
 * This HTTP interceptor handles the authorization requirements of the web API.
 * If a Crown ID has been selected by the user, the value is always sent as an HTTP header.
 * Error responses are also checked for unauthenticated responses (401) and redirects to the login page if one is found.
 */
@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

	/** The name of the HTTP header for the Crown ID. */
	private static readonly CROWN_IDENTIFIER_HEADER_NAME: string = "crown-identifier";

	/**
	 * @constructor
	 * 
	 * @param authenticationService The authentication service.
	 * @param router The router service.
	 * @param logger The logging service.
	 * @param userStateService The user state service.
	 */
	constructor(
		private readonly authenticationService: AuthenticationService,
		private readonly router: Router,
		private readonly logger: LoggerService,
		private readonly userStateService: UserStateService
	) { }

	/**
	 * Identified and handles a given HTTP request.
	 * 
	 * @param request The outgoing request object to handle.
	 * @param next The next interceptor in the chain, or the back-end if no interceptors remain in the chain.
	 */
	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let outgoingRequest: HttpRequest<any> = request;
		if (this.userStateService.State.CrownIdentifier != null)
			outgoingRequest = request.clone({ headers: request.headers.append(AuthenticationInterceptor.CROWN_IDENTIFIER_HEADER_NAME , this.userStateService.State.CrownIdentifier) });

		return next.handle(outgoingRequest)
			.pipe(
				catchError((error: any) => {
				if (error instanceof HttpErrorResponse) {
					if (error.status === HttpStatusCode.Unauthorized) {
						// Since the cookie has expired, we need to clear our local state such that the
						// timer call back that executes every 500 ms doesn't attempt to log out
						this.logger.TrackEvent(TelemetryIdentifiers.EVENT_UNAUTHENTICATED_REQUEST, { CrownIdentifier: this.userStateService.State.CrownIdentifier, UserName: this.userStateService.State.Username });
						this.authenticationService.ClearState();
						this.router.navigate(["/login"]);
					}
				}
				return throwError(() => error);
			}));
	}
}
