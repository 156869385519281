import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastListComponent } from './toast-list/toast-list.component';

/** This module is for toast messages. */
@NgModule({
declarations: [
	ToastListComponent
],
imports: [
	CommonModule
],
exports: [
	ToastListComponent
]
})
export class ToastModule { }
