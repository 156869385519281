<div class="login-container">
	<div class="login-image"></div>
	<div class="login-component">
		<img src="../../../assets/images/cds-logo-vertical.png" />
		<form #LoginForm="ngForm">
			<div class="form-group">
				<label for="Username">Email</label>
				<input crownxEmailInput type="text" id="Username" name="Username" required [(ngModel)]="LoginModel.UserName" #UserName="ngModel" autocomplete="username"/>
				<div id="invalid-username-container" crownx-validation-error *ngIf="UserName.invalid && (UserName.dirty || UserName.touched)">Please specify an email to continue.</div>
			</div>
			<div class="form-group">
				<label for="Password">Password</label>
				<input type="password" id="Password" name="Password" required [(ngModel)]="LoginModel.Password" #Password="ngModel" autocomplete="current-password"/>
				<div id="invalid-password-container" crownx-validation-error *ngIf="Password.invalid && (Password.dirty || Password.touched)">Please specify a valid password to continue.</div>
			</div>
			<div class="login-buttons">
				<button type="submit" id="login-submit-button" class="primary-button saving-feedback-button" [disabled]="LoginForm.invalid || !IsEmailValid || IsLoggingIn" (click)="OnLoginButtonClick()">
					<span>{{ IsLoggingIn ? "Logging In" : "Login" }}</span>
					<div *ngIf="IsLoggingIn && !LoginForm.invalid" class="loading-spinner"></div>
				</button>
			</div>
			<div id="invalid-credentials-container" crownx-validation-error *ngIf="CredentialsAreInvalid">The credentials were rejected by the server.<span *ngIf="UserIsLockedOut"><br />You have been locked out for 5 minutes.</span></div>
			<div class="password-reset-container">
				<a id="password-reset-link" class="crown-link-blue" href="/forgot-password" (click)="TrackForgotPasswordEvent()">Forgot Password</a>
			</div>
		</form>
		<p class="version-text">Version {{ VersionNumber }}</p>
		<crown-install-prompt />
	</div>
</div>
