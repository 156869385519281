import { Directive, ElementRef, AfterViewInit } from '@angular/core';

/** This directive will provide the correct styling to tags that are used to display input validation messages. */
@Directive({
  selector: '[crownx-validation-error]'
})
export class ValidationErrorDirective implements AfterViewInit {

	/**
	 * @constructor
	 * 
	 * @param _Element A reference to the DOM element attached to this directive.
	 */
	constructor(
		private _Element: ElementRef
	) { }

	/**
	 * Called after Angular has inserted all DOM elements.
	 */
    public ngAfterViewInit(): void {
		const element: HTMLElement = this._Element.nativeElement;
		const errorMessage: string = element.innerHTML;
		const subElement: HTMLElement = document.createElement("div");

		element.innerHTML = "";
		if (element.className == null || element.className == "")
			element.className = "validation-error";
		else
			element.className += " validation-error"
		subElement.innerHTML = errorMessage;
		element.appendChild(subElement);
    }

}
