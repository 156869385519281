import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthorizationService } from 'src/app/authorization/authorization.service';
import { TitleService } from '../../layout/title.service';
import { LoggerService } from '../../telemetry/logger.service';
import { AuthenticationService } from '../authentication.service';

/**
 * This component logs a user out of the app.
 */
@Component({
	selector: 'crownx-logout',
	templateUrl: './logout.component.html',
	styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit, OnDestroy {

	/** Indicates if the authentication service encountered an error. */
	public HasAuthenticationError: boolean = false;

	/** Used to unsubscribe from observables. */
	private unsubscriber = new Subject();

	/**
	 * @constructor
	 *
	 * @param authenticationService The authentication service.
	 * @param router The Angular router service.
	 * @param logger The logging service.
	 * @param titleService The title service.
	 * @param authorizationService The authorization service.
	 */
	constructor(
		private readonly authenticationService: AuthenticationService,
		private readonly router: Router,
		private readonly logger: LoggerService,
		private readonly titleService: TitleService,
		private readonly authorizationService: AuthorizationService
	) { }

	/**
	 * Component initialization.
	 */
	public ngOnInit(): void {
		this.titleService.Title = "Logout";
		this.authenticationService.GetAuthentication$(true)
			.pipe(takeUntil(this.unsubscriber))
			.subscribe({
				next: () => {
					const userName = this.authenticationService.UserName;
					this.logger.TrackTrace("Logging out user.", { UserName: userName });
					this.authenticationService.Logout$()
						.pipe(takeUntil(this.unsubscriber))
						.subscribe({
							next: () => {
								this.logger.TrackTrace("The user has logged out.", { UserName: userName });
								this.authorizationService.ClearUserAuthorization();
								this.router.navigate(["/login"]);
							},
							error: () => this.HasAuthenticationError = true
						});
				},
				error: (error: Error) => {
					this.HasAuthenticationError = true;
					this.logger.TrackError(`Error getting user during logout.`, error, { UserName: this.authenticationService.UserName });
				}
			});
	}

	/**
	 * Component destruction.
	 */
	public ngOnDestroy(): void {
		this.unsubscriber.next(null);
		this.unsubscriber.complete();
	}

}
