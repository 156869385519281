import { Component } from '@angular/core';
import { DialogService } from '../dialog.service';

/**
 * This component shows a layover for the site to ensure that users can only interact with a dialog.
 */
@Component({
	selector: 'crown-x-overlay',
	templateUrl: './overlay.component.html',
	styleUrls: ['./overlay.component.css']
})
export class OverlayComponent {

	/**
	 * @constructor
	 * 
	 * @param dialogService The service used to interact with dialogs.
	 */
	constructor(
		private readonly dialogService: DialogService
	) { }

	/**
	 * Indicates if the modal dialog layover is visible.
	 *
	 * @readonly
	 */
	public get IsModalLayoverVisible(): boolean {
		return this.dialogService.IsOverlayVisible;
	}

}