<div class="form-box-container">
	<div class="header form-box-section">
		<div class="content"><ng-content select="[header]"></ng-content></div>
	</div>
	<div class="body form-box-section">
		<div class="content"><ng-content select="[body]"></ng-content></div>
	</div>
	<div class="footer form-box-section">
		<div class="content"><ng-content select="[footer]"></ng-content></div>
	</div>
</div>