import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LoggerService } from './telemetry/logger.service';
import { CustomHttpParamEncoder } from './common/custom-http-param-encoder';

/**
 * Service for getting the server version.
 */
@Injectable({
	providedIn: 'root'
})
export class VersionDataService {

	/** The path to the version controller. */
	private static readonly CONTROLLER_PATH: string = "/api/version";

	/**
	 * @constructor
	 *
	 * @param httpClient The HTTP client to use for web requests.
	 * @param logger The logging service.
	 */
	constructor(
		private readonly httpClient: HttpClient,
		private readonly logger: LoggerService
	) { }

	/**
	 * Gets the current server version.
	 * @returns An observable with the current server version.
	 */
	public GetVersion$(): Observable<string> {
		try {
			const url: string = VersionDataService.CONTROLLER_PATH;
			const options = {
				params: new HttpParams({ encoder: new CustomHttpParamEncoder() }).append("timestamp", Date.now()*10000),
				responseType: 'text' as const,
			};
			return this.httpClient.get(url, options);
		}
		catch (error: any) {
			this.logger.TrackError("Unable to build version request", error);
			return throwError(error);
		}
	}
}
