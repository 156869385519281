import { NgModule } from '@angular/core';
import { NotificationBadgeComponent } from './notification-badge/notification-badge.component';
import { CommonModule } from '@angular/common';

/**
 * Defines and exports components for providing users with notification badges in the Crown X ecosystem.
 */
@NgModule({
	declarations: [
		NotificationBadgeComponent
	],
	imports: [
		CommonModule
	],
	exports: [
		NotificationBadgeComponent
	]
})
export class NotificationBadgeModule { }