import { formatDate } from '@angular/common';
import { Authorization } from '../authorization/authorization';
import { AuthorizationConstants } from '../authorization/authorization-constants';
import { LicenseAllocation } from '../authorization/license-allocation';
import { MapStop } from '../maps/stops/map-stop';
import { RoutePlanStop } from '../maps/stops/route-plan-stop';
import { IconMarkerStyle, StopIcon } from '../maps/stops/stop-icon';

/**
 * Converts a Date object into a .NET DateTimeOffset object.
 *
 * @param date The Date object to convert.
 * @returns A string that can be interpreted as a DateTimeOffset.
 */
export function formatAsDateTimeOffsetForQuerystring(date: Date): string {
	return encodeURIComponent(formatDate(date, "yyyy-MM-ddTHH:mm:ss.SSS Z", "en-US"));
}

/**
 * Checks if a date is today.
 * @param date The date to check.
 * @returns True if the date is today.
 */
export function isToday(date: Date | null): boolean {
	const today = new Date();
	let dateIsToday: boolean = areDatesSame(date, today);
	return dateIsToday;
}

/**
 * Checks if a date is the same as another date.
 * @param date1 The first date to check.
 * @param date2 The second date to check.
 * @returns True if the dates are the same.
 */
 export function areDatesSame(date1: Date | null, date2: Date | null): boolean {
	let datesAreSame: boolean = date1?.getDate() == date2?.getDate() &&
	date1?.getMonth() == date2?.getMonth() &&
	date1?.getFullYear() == date2?.getFullYear();
	return datesAreSame;
}

/**
 * Calculates the stop/driver icon class shape.
 * @param icon The driver or stop icon.
 * @returns A class name for the shape of the icon.
 */
export function calculateIconClass(icon: StopIcon | null): string {
	switch (icon?.MarkerStyle) {
		case IconMarkerStyle.Triangle:
			return "triangle";
		case IconMarkerStyle.Diamond:
			return "diamond";
		case IconMarkerStyle.Circle:
			return "circle";
		default:
			return "square";
	}
}

/**
 * Calculates the stop/driver icon styles.
 * @param icon The driver or stop icon.
 * @returns An object containing the color and style of the icon.
 */
export function calculateIconStyles(icon: StopIcon | null): Object {
	switch (icon?.MarkerStyle) {
		case IconMarkerStyle.Triangle:
			return {"border-bottom-color": `#${icon?.BackColor?.toLowerCase().slice(2)}`};
		case IconMarkerStyle.Diamond:
			return {"background-color": `#${icon?.BackColor?.toLowerCase().slice(2)}`};
		case IconMarkerStyle.Circle:
			return {"background": `#${icon?.BackColor?.toLowerCase().slice(2)}`};
		case IconMarkerStyle.Square:
			return {"background": `#${icon?.BackColor?.toLowerCase().slice(2)}`};
		default:
			return {"background": "#d3d3d3"};
	}
}

/**
 * Indicates if a user has a current license of a certain type or has the license bypass role.
 * @param auth The user's authorization
 * @param licenseToCheck The license to check against the user's licenses.
 * @returns True if the user has the agent license bypass role or if the user has the license.
 */
export function isUserLicensed(auth: Authorization, licenseToCheck: string): boolean {
	return auth.Roles?.includes(AuthorizationConstants.AGENT_LICENSE_BYPASS_ROLE) ||
		Boolean(auth.CurrentAgentLicenses?.AllocatedLicenses?.filter((license: LicenseAllocation) => license.License == licenseToCheck).length);
}

/**
 * Determines if two stops are equal.
 * @param stop1 The first stop to compare.
 * @param stop2 The second stop to compare.
 * @returns True if both stops have an Id and they are equal; True if the tag number, latitude, and longitude of both stops are equal; Null if either of the stops do not have stop location or shipment info.
 */
export function areStopsEqual(stop1: RoutePlanStop | MapStop, stop2: RoutePlanStop | MapStop): boolean | null {
	// If both stops have an identifier, use that to determine equality because Id is assigned when stops are placed on a route
	// It is possible for the stops to be the same, but one not have Id defined because it is being generated without the stop's route context
	// As such, if either stop does not have a defined Id, we cannot rely on this check to determine equality
	// This check is necessary however to determine uniqueness amongst stops without locations or shipments which [currently] can only exist on routes
	if ("Id" in stop1 && "Id" in stop2) {
		if (Boolean(stop1.Id) && Boolean(stop2.Id)) {
			return stop1.Id == stop2.Id;
		}
	}

	// If we cannot use Id to determine uniqueness, use location and shipment information
	// If any of these properties are not populated on the stops being compared, the two stops probably are not equal, but return null to indicate that this is inconclusive
	if (!stop1.StopLocation
		|| !stop1.StopShipmentInfo
		|| !stop2.StopLocation
		|| !stop2.StopShipmentInfo)
		return null;

	// If we get here, stops are considered the same if they have the same tag number (same shipment) and location on the map (same logistic action for the shipment)
	if (stop1.StopShipmentInfo.TagNumber == stop2.StopShipmentInfo.TagNumber
		&& stop1.StopLocation.Latitude == stop2.StopLocation.Latitude
		&& stop1.StopLocation.Longitude == stop2.StopLocation.Longitude)
		return true;

	return false;
}

/**
 * Converts a Date to DateTimeOffset string format.
 * @param date The Date to convert.
 * @returns The DateTimeOffset of the Date in string format.
 */
export function toIsoString(date: Date): string {
	let timezone: number = -date.getTimezoneOffset();
	let difference: string = timezone >= 0 ? '+' : '-';
	let pad: (num: number) => string = (num: number) => (num < 10 ? '0' : '') + num;

	return date.getFullYear() +
		'-' + pad(date.getMonth() + 1) +
		'-' + pad(date.getDate()) +
		'T' + pad(date.getHours()) +
		':' + pad(date.getMinutes()) +
		':' + pad(date.getSeconds()) +
		difference + pad(Math.floor(Math.abs(timezone) / 60)) +
		':' + pad(Math.abs(timezone) % 60);
}

/**
 * Indicates if nullable date is null or an empty string.
 * @param date The date to check.
 * @returns True if the date is null or an empty string.
 */
export function isNullableDateNullOrEmpty(date: Date | string | null): boolean {
	return date == null || date == "";
}

/**
 * Indicates if a date is null, an empty string, or a valid date.
 * @param date The date to check.
 * @returns True if the date is null, an empty string, or a valid date.
 */
export function isDateNullEmptyOrValidDate(date: Date | string | null): boolean {
	return isNullableDateNullOrEmpty(date) || !isNaN(Date.parse(date!.toString()));
}

/**
 * Indicates if a date is null, an empty string, or an invalid date.
 * @param date The date to check.
 * @returns True if the date is null, an empty string, or an invalid date.
 */
 export function isDateNullEmptyOrInvalidDate(date: Date | string | null): boolean {
	return isNullableDateNullOrEmpty(date) || isNaN(Date.parse(date!.toString()));
}

/**
 * Indicates if the date is a valid date and is not null or empty.
 * @param date The date to check.
 * @returns True is the date is not null or an empty string and is a valid date.
 */
export function isValidDate(date: Date | string | null): boolean {
	return date != null && date != "" && !isNaN(Date.parse(date.toString()));
}

/**
 * Gets the current local date and time in a properly formatted string.
 * @returns The current local date and time in a properly formatted string.
 */
export function getCurrentLocalDateTimeString() : string {
	return formatDate(new Date(), "yyyy-MM-ddTHH:mm:ss", "en-US");
}

/**
 * Encodes a string for HTML output, escaping some characters like ampersand.
 * @param input The input string to encode.
 * @returns A string which is HTML encoded.
 */
export function htmlEncode(input: string | null | undefined): string {
	if (input == null)
		return "";
	else {
		let modifiedInput: string = input
			.replace(/&/g, '&amp;')
			.replace(/'/g, '&apos;')
			.replace(/"/g, '&quot;')
			.replace(/>/g, '&gt;')
			.replace(/</g, '&lt;');
		return modifiedInput;
	}
}