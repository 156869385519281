<div id="box">
	<div id="box-header" *ngIf="ShowTitleBar"><crownx-title-bar></crownx-title-bar></div>
	<div class="box-toolbar" *ngIf="IsAuthenticated">
		<div class="box-toolbars crown-blue-background flex-row">
			<crown-x-menu-toolbar></crown-x-menu-toolbar>
			<div class="flex-row right-toolbar">
				<crown-x-page-toolbar></crown-x-page-toolbar>
				<crown-x-network-connectivity></crown-x-network-connectivity>
			</div>
		</div>
	</div>
	<div id="box-map"><router-outlet></router-outlet></div>
</div>
