import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Authorization } from './authorization';
import { catchError, tap } from 'rxjs/operators';
import { UserStateService } from '../user-state/user-state.service';
import { AuthorizationConstants } from './authorization-constants';
import { LoggerService } from '../telemetry/logger.service';

/** Service for querying user data. */
@Injectable({
	providedIn: 'root'
})
export class AuthorizationService {

	/** The path to the user controller. */
	private static readonly CONTROLLER_PATH: string = "/api/Authorization";

	/** The local storage key used to save the user authorization data. */
	private static readonly LOCAL_STORAGE_KEY: string = "crownx-user-authorization";

	/** The url for the page which was visited before switching authorization. */
	private preAuthorizationPageUrl: string = "";

	/** A behavior subject used to indicate if a user gets an authorization. */
	private userAuthorizationBehaviorSubject: BehaviorSubject<Authorization | null> = new BehaviorSubject<Authorization | null>(null);

	/** The stored user authorization data. */
	private userAuthorization: Authorization | null = null;

	/**
	 * @constructor
	 *
	 * @param httpClient The HTTP client to use for web requests.
	 * @param userStateService The user state service.
	 * @param logger The logging service.
	 */
	constructor(
		private readonly httpClient: HttpClient,
		private readonly userStateService: UserStateService,
		private readonly logger: LoggerService
	) { }

	/**
	 * Gets the user's authorization information.
	 *
	 * @returns An observable with the user's information.
	 */
	public GetAuthorization$(): Observable<Authorization> {
		const url: string = `${AuthorizationService.CONTROLLER_PATH}`;
		return this.httpClient.get<Authorization>(url, { withCredentials: true })
				.pipe(tap(
					(auth: Authorization) => {
							this.SaveUserAuthorizationData(auth);
							this.userStateService.SetTelemetryFilter(Boolean(auth.Roles?.includes(AuthorizationConstants.CLIENT_EVENT_TELEMETRY_BYPASS_ROLE)));
							this.userAuthorizationBehaviorSubject.next(auth);
						}),
						catchError((error: Error) => {
							if(!this.userAuthorization)
								this.userAuthorization = this.LoadUserAuthorizationData();
							return of(this.userAuthorization!)
						})
					);
	}

	/**
	 * Clears the stored user authorization.
	 */
	public ClearUserAuthorization(): void {
		localStorage.removeItem(AuthorizationService.LOCAL_STORAGE_KEY);
		this.userAuthorization = null;
		this.userAuthorizationBehaviorSubject.next(null);
	}

	/*
	 * Gets the url for the page which was visited before switching authorization.
	 */
	public get PreAuthorizationPageUrl(): string {
		return this.preAuthorizationPageUrl;
	}

	/*
	 * Sets the url for the page which was visited before switching authorization.
	 */
	public set PreAuthorizationPageUrl(url: string) {
		this.preAuthorizationPageUrl = url;
	}

	/**
	 * Indicates when a user gets an authorization.
	 * @returns An observable for when a user gets an authorization.
	 */
	public OnGetAuthorization$(): Observable<Authorization | null> {
		return this.userAuthorizationBehaviorSubject.asObservable();
	}

	/**
	 * Saves the user's authorization data to local storage.
	 * @param auth The stored user authorization data.
	 */
	private SaveUserAuthorizationData(auth: Authorization): void {
		this.userAuthorization = auth;

		this.logger.TrackTrace("Saving user authorization to local storage...");
		const startDate: Date = new Date();
		localStorage.setItem(AuthorizationService.LOCAL_STORAGE_KEY, JSON.stringify(auth));
		const endDate: Date = new Date();
		const operationMilliseconds: number = endDate.getTime() - startDate.getTime();
		this.logger.TrackTrace(`Loaded user authorization in ${operationMilliseconds}ms.`)
	}

	/**
	 * Loads the user's authorization data from local storage.
	 * @returns The user stored authorization data.
	 */
	private LoadUserAuthorizationData(): Authorization {
		this.logger.TrackTrace("Loading user authorization from local storage...");
		const startDate: Date = new Date();
		const storedUserAuthorizationData: string | null = localStorage.getItem(AuthorizationService.LOCAL_STORAGE_KEY)
		const userAuthorization = JSON.parse(storedUserAuthorizationData!);
		const endDate: Date = new Date();
		const operationMilliseconds: number = endDate.getTime() - startDate.getTime();
		this.logger.TrackTrace(`Loaded user authorization in ${operationMilliseconds}ms.`)

		return userAuthorization;
	}
}