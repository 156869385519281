import { ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

/**
 * Directive used to validate email fields.
 */
@Directive({
	selector: '[crownxEmailInput]'
})
export class EmailInputDirective implements OnInit {

	/** The regular expression for testing email addresses. */
	public static readonly PATTERN: RegExp = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;

	/**
	 * @constructor
	 * @param control A reference to the control on which the directive has been placed.
	 * @param renderer The Angular renderer used to add and remove classes from an HTML element.
	 * @param hostElement The reference to the host HTML element for the input.
	 *
	 */
	constructor(
		private readonly control: NgControl,
		private readonly renderer: Renderer2,
		private readonly hostElement: ElementRef
		)
		{ }

	/**
	 * Directive initialization.
	 */
	public ngOnInit(): void {
		setTimeout(() => {
			this.EvaluateInput();
		}, 500);
	}

	/**
	 * Event handler for when the user types into the input field.
	 * @param event The input event.
	 */
	@HostListener("input", ["$event"])
	public OnInputChange(event: Event): void {
		this.EvaluateInput();
	}

	/**
	 * Evaluates the current control value against the pattern.
	 */
	private EvaluateInput(): void {
		const currentValue: string = this.hostElement.nativeElement.value;
		if (!currentValue && !this.hostElement.nativeElement.required) {
			this.control.control?.setErrors(null);
			this.renderer.removeClass(this.hostElement.nativeElement, 'ng-invalid');
			this.renderer.addClass(this.hostElement.nativeElement, 'ng-valid');
			return;
		}
		if (!currentValue?.match(EmailInputDirective.PATTERN)) {
			this.control.control?.setErrors({ 'invalid-email-format': true });
			this.renderer.addClass(this.hostElement.nativeElement, 'ng-invalid');
			this.renderer.removeClass(this.hostElement.nativeElement, 'ng-valid');
		}
		else {
			this.control.control?.setErrors(null);
			this.renderer.removeClass(this.hostElement.nativeElement, 'ng-invalid');
			this.renderer.addClass(this.hostElement.nativeElement, 'ng-valid');
		}
	}
}
