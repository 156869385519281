import { Injectable } from '@angular/core';

/**
 * This service facilitates changing the page title.
 */
@Injectable({
	providedIn: 'root'
})
export class TitleService {

	/** The field for the Title property. */
	private title: string | null = null;

	/**
	 * The current title of the page.
	 */
	public get Title(): string | null {
		return this.title;
	}

	/**
	* The current title of the page.
	*/
	public set Title(value: string | null) {
		const titleTag: HTMLElement = document.getElementsByTagName("title")[0];
		if (value == null) {
			value = "";
			titleTag.innerText = "Crown X";
		}
		else
			titleTag.innerText = value || "";

		this.title = value;
	}

}