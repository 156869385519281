import { Injectable } from '@angular/core';
import { DialogComponent } from './dialog/dialog.component';

/**
 * This service is used to interact with dialogs.
 */
@Injectable({
	providedIn: 'root'
})
export class DialogService {

	/** Indicates if the modal overlay is visible. */
	private isOverlayVisible: boolean = false;

	/**
	 *	Indicates if the modal overlay is visible.
	 *
	 *	@readonly
	 */
	public get IsOverlayVisible(): boolean {
		return this.isOverlayVisible;
	}

	/**
	 * Shows a modal dialog.
	 * 
	 * @param dialog The dialog component to show.
	 */
	public ShowModalDialog(dialog: DialogComponent) {
		this.isOverlayVisible = true;
		dialog.ShowDialog();
	}

	/**
	 * Hides a modal dialog.
	 * 
	 * @param dialog The dialog component to hide.
	 */
	public HideModalDialog(dialog: DialogComponent) {
		this.isOverlayVisible = false;
		dialog.HideDialog();
	}

}