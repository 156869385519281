import { Injectable } from '@angular/core';
import { ApplicationInsights, IMetricTelemetry } from '@microsoft/applicationinsights-web';
import { environment } from '../../environments/environment';
import { UUID } from 'angular2-uuid';
import { formatDate } from '@angular/common';
import { HttpRequest, HttpResponseBase } from '@angular/common/http';

/**
 * This service is used to log telemetry to the console and track it in Application Insights.
 */
@Injectable()
export class LoggerService {

	/** The Application Insights client used to send data to Azure. */
	private appInsightsClient: ApplicationInsights | null = null;

	/**
	 * @constructor
	 */
	constructor() {
		if (environment.ApplicationInsightsKey != null && environment.ApplicationInsightsKey != "") {
			this.appInsightsClient = new ApplicationInsights({
				config: {
					instrumentationKey: environment.ApplicationInsightsKey,
					enableAutoRouteTracking: true
				}
			});
			try {
				this.appInsightsClient.loadAppInsights();
				this.appInsightsClient.trackPageView();
			}
			catch (ex) {
				console.error("Application Insights threw an exception while loading and tracking", ex);
			}
		}
	}

	/**
	 * Tracks a custom event.
	 *
	 * @param eventName The name of the custom event.
	 * @param properties A property bag of key-value pairs to associate with the event.
	 */
	public TrackEvent(eventName: string, properties?: { [key: string]: any }): void {
		console.log(`${formatDate(new Date(), "yyyy-MM-dd hh:mm:ss.SSS", "en-US")} Event: ${eventName}`);
		if (properties != null)
			console.log(properties);

		try {
			if (this.appInsightsClient != null)
			this.appInsightsClient.trackEvent({
				name: eventName,
				properties: properties
			});
		}
		catch (ex) {
			console.error("Application Insights threw an exception while attempting to track an event.", ex);
		}

	}

	/**
	 * Logs an error to the console and Application Insights.
	 *
	 * @param message The message to log.
	 * @param error The error to be tracked.
	 * @param properties A property bag of key-value pairs to associate with the error.
	 */
	public TrackError(message: string, error: Error, properties?: { [key: string]: any }): void {
		console.error(`${formatDate(new Date(), "yyyy-MM-dd hh:mm:ss.SSS", "en-US")} ${message}`, error);

		if (properties != null)
			console.log(properties);

		try {
			this.appInsightsClient?.trackException({
				error: error,
				properties: properties
			});
		}
		catch (ex) {
			// Log to the console and silently fail so as to not get into a constant error loop with the global error handler.
			console.error("Application Insights threw an exception while attempting to track another exception.", ex);
		}
	}

	/**
	 * Tracks a log message.
	 *
	 * @param message The message to be tracked.
	 * @param properties A property bag of key-value pairs to associate with the message.
	 */
	public TrackTrace(message: string, properties?: { [key: string]: any }): void {
		console.log(`${formatDate(new Date(), "yyyy-MM-dd hh:mm:ss.SSS", "en-US")} ${message}`);
		if (properties != null)
			console.log(properties);

		try {
			if (this.appInsightsClient != null)
				this.appInsightsClient.trackTrace({
					message: message,
					properties: properties
				});
		}
		catch (ex) {
			console.error("Application Insights threw an exception while attempting to track a trace.", ex);
		}
	}

	/**
	 * Tracks a metric.
	 *
	 * @param metricName The name of the metric.
	 * @param measurements Either a number which is the measurement of the metric or a dictionary of named measurements.
	 * @param properties A property bag of key-value pairs to associate with the event.
	 */
	public TrackMetric(metricName: string, measurements: { [key: string]: number } | number, properties?: { [key: string]: any }): void {
		let metric: IMetricTelemetry;
		if (typeof (measurements) == "number") {
			metric = {
				name: metricName,
				average: measurements,
				min: measurements,
				max: measurements,
				sampleCount: 1,
				properties: properties
			};

			console.log(`${formatDate(new Date(), "yyyy-MM-dd hh:mm:ss.SSS", "en-US")} Metric: ${metricName} Value: ${measurements}`);
		}
		else {
			const keys: string[] = Object.keys(measurements);
			const values: number[] = keys.map(key => measurements[key]);
			const average: number = values.reduce((sum, item) => sum + item) / keys.length;
			const minimum: number = values.reduce((min, item) => (item < min ? item : min));
			const maximum: number = values.reduce((max, item) => (item > max ? item : max));
			metric = {
				name: metricName,
				measurements: measurements,
				properties: properties,
				average: average,
				min: minimum,
				max: maximum,
				sampleCount: keys.length
			};

			console.log(`${formatDate(new Date(), "yyyy-MM-dd hh:mm:ss.SSS", "en-US")} Metric: ${metricName} Average: ${average}`);
			keys.forEach(key => console.log(`  Metric: ${metricName} Measure: ${key} Value: ${measurements[key]}`));
		}

		try {
			this.appInsightsClient?.trackMetric(metric);
		}
		catch (ex) {
			console.error("Application Insights threw an exception while attempting to track a metric.", ex);
		}
	}

	/**
	 * Logs the start of an HTTP call to the console.
	 *
	 * @param request The HTTP request to log.
	 * @param requestIdentifier A unique identifier used for correlation.
	 */
	public TrackHttpStart(request: HttpRequest<any>, requestIdentifier: number): void {
		console.log(`${formatDate(new Date(), "yyyy-MM-dd hh:mm:ss.SSS", "en-US")} [HTTP] (id={${requestIdentifier}}) START ${request.method} "${request.urlWithParams}`);
		if (request.url != "/api/Authentication" || request.method != "POST")
			console.log(request);
	}

	/**
	 * Logs the end of an HTTP call to the console and Application Insights.
	 *
	 * @param startInfo The HTTP dependency start information collected before the HTTP request.
	 * @param payload The payload being received in the response.
	 */
	public TrackHttpEnd(request: HttpRequest<any>, requestIdentifier: number, response: HttpResponseBase, requestResult: string, requestStartTime: Date): void {
		const HTTP_DEPENDENCY_TYPE: string = "HTTP";
		const endTime = new Date();
		const requestDurationMilliseconds = endTime.getTime() - requestStartTime.getTime();

		const statusCode = response != null ? response.status : 0;
		console.log(`${formatDate(new Date(), "yyyy-MM-dd hh:mm:ss.SSS", "en-US")} [HTTP] (id={${requestIdentifier}}) END   ${request.method} "${request.url}" ${requestResult} with status code ${statusCode} in ${requestDurationMilliseconds} ms.`);

		if (response != null) {
			console.log(response);
		}

		try {
			this.appInsightsClient?.trackDependencyData({
				id: UUID.UUID(),
				type: HTTP_DEPENDENCY_TYPE,
				target: request.url,
				correlationContext: requestIdentifier.toString(),
				responseCode: statusCode,
				duration: requestDurationMilliseconds,
				startTime: requestStartTime
			});
		}
		catch (ex) {
			console.error("Application Insights threw an exception while attempting to track dependency data.", ex);
		}
	}

}
