import { Component, Input } from "@angular/core";

/**
 * This component is used to display form box headers.
 */
@Component({
	selector: 'crown-x-form-box-header',
	templateUrl: './form-box-header.component.html',
	styleUrls: ['./form-box-header.component.css']
})
export class FormBoxHeaderComponent {

	/** The header title. */
	@Input()
	public Title: string = '';

	/** The header subtitle. */
	@Input()
	public SubTitle: string | null = null;

	/** The header icon. */
	@Input()
	public IconName: string | null = null;

	/** The css id for the icon. */
	@Input()
	public IconCssId: string = 'material-icons';

}