import { EventEmitter, Injectable } from "@angular/core";
import { TelemetryIdentifiers } from "../telemetry/telemetry-identifiers";
import { LoggerService } from "../telemetry/logger.service";
import { UserState } from "./user-state";
import { BehaviorSubject, Observable } from "rxjs";

/**
 * The service responsible for managing user-state.
 */
@Injectable({
	providedIn: 'root'
})
export class UserStateService {

	/** The local storage key used to save the user state. */
	private static readonly LOCAL_STORAGE_KEY: string = "crownx-user-state";

	/** The user's state, if it's been loaded. */
	private state!: UserState;

	/** Indicates if the state has been loaded/saved from local storage. */
	private isStateLoaded: boolean = false;

	/** Indicates if a user chose a new crown identifier. */
	private newCrownIdentifierSetBehaviorSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null> (null);

	/**
	 * @constructor
	 *
	 * @param logger The logging service.
	 */
	constructor(
		private readonly logger: LoggerService
	) { }

	/**
	 * The user's state.
	 *
	 * @readonly
	 */
	public get State(): UserState {
		if (!this.isStateLoaded)
			this.LoadUserStateData();

		return this.state;
	}

	/**
	 * Sets the user's Crown ID.
	 *
	 * @param crownIdentifier The Crown ID to set.
	 */
	public SetCrownIdentifier(crownIdentifier: string | null): void {
		this.state = new UserState(crownIdentifier, this.state.MapDate, this.state.Username, this.state.FilterTelemetry);
		this.SaveUserStateData();
		this.newCrownIdentifierSetBehaviorSubject.next(crownIdentifier);
	}

	/**
	 * Sets the user's map date.
	 * @param mapDate The map date to set.
	 */
	public SetMapDate(mapDate: Date | null): void {
		this.state = new UserState(this.state.CrownIdentifier, mapDate, this.state.Username, this.state.FilterTelemetry);
		this.SaveUserStateData();
	}

	/**
	 * Sets the user's telemetry filter flag.
	 * @param filterTelemetry.
	 */
	public SetTelemetryFilter(filterTelemetry: boolean | null): void {
		this.state = new UserState(this.state.CrownIdentifier, this.state.MapDate, this.state.Username, filterTelemetry);
		this.SaveUserStateData();
	}

	/**
	 * Sets the user's username.
	 * @param username.
	 */
	public SetUsername(username: string | null): void {
		this.state = new UserState(this.state.CrownIdentifier, this.state.MapDate, username, this.state.FilterTelemetry);
		this.SaveUserStateData();
	}

	/**
	 * Clears the user state.
	 */
	public ClearState(): void {
		this.state = new UserState(null, null, null, null);
		this.SaveUserStateData();
		this.newCrownIdentifierSetBehaviorSubject.next(null);
	}

	/**
	 * Loads the user's state data from local storage.
	 */
	private LoadUserStateData(): void {
		this.logger.TrackTrace("Loading user state from local storage...");
		const startDate: Date = new Date();
		const storedState: string | null = localStorage.getItem(UserStateService.LOCAL_STORAGE_KEY);
		const endDate: Date = new Date();
		const operationMilliseconds: number = endDate.getTime() - startDate.getTime();
		this.logger.TrackTrace(`Loaded user state in ${operationMilliseconds}ms.`)
		this.logger.TrackMetric(TelemetryIdentifiers.METRIC_USER_STATE_READ, operationMilliseconds);

		if (storedState == null)
			this.state = new UserState(null, null, null, null);
		else {
			const loadedState: UserState = JSON.parse(storedState);
			this.state = new UserState(loadedState.CrownIdentifier,
				loadedState.MapDate ? new Date(loadedState.MapDate) : null,
				loadedState.Username,
				loadedState.FilterTelemetry);
		}
		this.isStateLoaded = true;
	}

	/**
	 * Saves the user's state data to local storage.
	 */
	private SaveUserStateData(): void {
		this.logger.TrackTrace("Saving user state to local storage...");
		const startDate: Date = new Date();
		localStorage.setItem(UserStateService.LOCAL_STORAGE_KEY, JSON.stringify(this.state));
		const endDate: Date = new Date();
		const operationMilliseconds: number = endDate.getTime() - startDate.getTime();
		this.logger.TrackTrace(`Loaded user state in ${operationMilliseconds}ms.`)
		this.logger.TrackMetric(TelemetryIdentifiers.METRIC_USER_STATE_WRITE, operationMilliseconds);

		this.isStateLoaded = true;
	}

	/**
	 * Signals that the crown identifier changed.
	 * @returns An observable with the changed crown identifier.
	 */
	 public OnCrownIdentifierChanged$(): Observable<string | null> {
		return this.newCrownIdentifierSetBehaviorSubject.asObservable();
	}
}