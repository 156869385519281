<div *ngIf="Visible" class="install-pwa-container">
	<div class="install-pwa-header">
		<img src="/assets/favicon.ico" />
		<span>Install Crown X</span>
		<button class="install-pwa-close-button" (click)="OnInstallPwaCloseButtonClick()">X</button>
	</div>
	<div class="install-pwa-separator"></div>
	<p>Install Crown X on your device to easily access it anytime. No app store, no download, no hassle.</p>
	<div class="button-container">
		<button class="install-pwa-button primary-button" (click)="OnInstallPwaButtonClick()"><span>Install</span></button>
	</div>
</div>