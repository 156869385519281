/**
 * Represents a user session state.
 */
export interface UserSessionState {

	/**The user session state. */
	State: UserSessionStateType;
}

/**
 * Represents the user session state type.
 */
export enum  UserSessionStateType {

	/** Indicates when the session is active. */
	Active = 0,

	/**Indicates when the session has timed out. */
	TimedOut = 1,

	/** Indicates when the user has logged in elsewhere and has a new session. */
	LoggedInElsewhere = 2
}