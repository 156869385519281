import { Component, OnInit } from '@angular/core';
import { TitleService } from '../layout/title.service';

/**
 * This component is displayed when a route is not found.
 */
@Component({
	selector: 'crownx-page-not-found',
	templateUrl: './page-not-found.component.html',
	styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

	/**
	 * @constructor
	 * 
	 * @param titleService The title service.
	 */
	constructor(
		private readonly titleService: TitleService
	) { }

	/**
	 * Component initialization.
	 */
	public ngOnInit(): void {
		this.titleService.Title = "Page Not Found";
	}

}