import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * This component is used to display dialog boxes.
 */
@Component({
	selector: 'crown-x-dialog',
	templateUrl: './dialog.component.html',
	styleUrls: ['./dialog.component.css']
})
export class DialogComponent {

	/** Indicates if the dialog is currently visible. */
	private isVisible: boolean = false;

	/** The title of the dialog. */
	@Input()
	public DialogTitle: string | undefined;

	/** The text for the "ok" button. */
	@Input()
	public OkText: string = "Okay";

	/** The text for the "cancel" button. */
	@Input()
	public CancelText: string = "Cancel";

	/** Indicates if the form input is valid.  Setting this to false will disable the ability to submit the form. */
	@Input()
	public IsFormValid: boolean = true;

	/** Indicates if the default ok and cancel buttons should be displayed. */
	@Input()
	public ShowDefaultButtons: boolean = true;

	/** Deteremines if the loading Ok button is loading. */
	@Input()
	public IsOkLoading: boolean = false;

	/** The text of the loading Ok button. */
	@Input()
	public OkLoadingText: string = "";

	/** Event emitter for clicking the "ok" button. */
	@Output()
	public OkButtonClick: EventEmitter<void> = new EventEmitter<void>();

	/** Event emitter for clicking the "cancel" button. */
	@Output()
	public CancelButtonClick: EventEmitter<void> = new EventEmitter<void>();

	/**
	 * Shows the dialog box.
	 */
	public ShowDialog(): void {
		this.isVisible = true;
	}

	/**
	 * Hides the dialog box.
	 */
	public HideDialog(): void {
		this.isVisible = false;
	}

	/**
	 * Indicates if the dialog is currently visible.
	 *
	 * @readonly
	 */
	public get IsVisible(): boolean {
		return this.isVisible;
	}

	/**
	 * Event handler for clicking the "ok" button.
	 */
	public OnOkButtonClick(): void {
		this.OkButtonClick.emit();
	}

	/**
	 * Event handler for clicking the "cancel" button.
	 */
	public OnCancelButtonClick(): void {
		this.CancelButtonClick.emit();
	}

}