<div class="top-bar">
	<div class="logo"><img id="logo" alt="Crown X" src="../../../assets/images/cds-logo-horizontal.png" /></div>
	<div class="logo-vertical-rule"><div>&nbsp;</div></div>
	<h1 id="page-title">{{Title}}</h1>
	<div class="authorization-info" *ngIf="IsAuthenticated">
		<div>
			<div class="username" id="username">{{UserName}}</div>
			<div *ngIf="CompanyName" class="company-name" id="company-name">{{CompanyName}}</div>
		</div>
		<div #UserMenuDropdown class="user-menu flex-row">
			<button id="userMenuToggle" class="user-menu-toggle no-style" (click)="OnUserMenuDropDown()">
				<svg xmlns="http://www.w3.org/2000/svg" height="36" viewBox="0 -960 960 960" width="36" fill="#000">
					<path d="M0 0h24v24H0V0z" fill="none" />
					<!--Down Arrow to Open-->
					<path *ngIf="!UserMenuOpen" d="M480-360 280-559h400L480-360Z" stroke-width="3" stroke="black" />
					<!--Up Arrow to Close-->
					<path *ngIf="UserMenuOpen" d="m280-400 200-201 200 201H280Z" stroke-width="3" stroke="black" />
				</svg>
			</button>
			<div class="user-menu-content" *ngIf="UserMenuOpen">
				<div class="user-menu-item" *ngIf="HasMultipleCrownIds">
					<button (click)="ChangeCompany()" class="no-style">
						<div class="user-menu-text">Switch</div>
					</button>
				</div>
				<div class="user-menu-item">
					<button (click)="Logout()" id="logout" class="no-style">
						<div class="user-menu-text">Logout</div>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>