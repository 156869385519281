import { Component } from '@angular/core';

/**
 * This component displays the layout for authenticated users.
 */
@Component({
	selector: 'crownx-authenticated-layout',
	templateUrl: './authenticated-layout.component.html',
	styleUrls: ['./authenticated-layout.component.css']
})
export class AuthenticatedLayoutComponent { }