import { Injectable } from '@angular/core';
import { Observable, defer, from, of, tap } from 'rxjs';

/**
 * Service for handling WakeLock
 */
@Injectable({
	providedIn: 'root'
})
export class WakeLockService {

	/**
	 * The instance of the WakeLock
	 */
	private wakeLock: WakeLockSentinel | null = null;

	/**
	 * Checks to see if the WakeLock API is supported and the wake lock is not set
	 * @returns true if WakeLock API is supported and the wake lock is null or released
	 * @readonly
	 */
	public get IsWakeLockSupportedAndNotSet(): boolean {
		return this.IsWakeLockSupported && (!this.IsWakeLockSet || this.IsWakeLockReleased);
	}

	/**
	 * Checks to see if the WakeLock API is supported
	 * @returns true if WakeLock API is supported
	 * @readonly
	 */
	private get IsWakeLockSupported(): boolean {
		return 'wakeLock' in navigator;
	}

	/**
	 * Checks to see if the WakeLock exists.
	 * @returns true if WakeLock is not null
	 * @readonly
	 */
	private get IsWakeLockSet(): boolean {
		return Boolean(this.wakeLock);
	}

	/**
	 * Checks to see if the WakeLock is released
	 * @returns true if the WakeLock exists and is released
	 * @readonly
	 */
	private get IsWakeLockReleased(): boolean {
		return Boolean(this.wakeLock?.released)
	}

	/**
	 * Makes sure WakeLock does not exists.
	 * If WakeLock exists but is released, deletes WakeLock first.
	 * Accesses the WakeLock API to set the WakeLock
	 * @returns Observable<WakeLockSentinel> if WakeLock successfully requested or Observable<null> if WakeLock not requested
	 */
	public RequestWakeLock$(): Observable<WakeLockSentinel | null> {
		if (this.IsWakeLockSet)
		{
			if (this.IsWakeLockReleased)
				this.wakeLock = null;
			else
				return of(null);
		}
		try {
			return defer(() => from(navigator.wakeLock.request('screen'))).pipe(tap((sentinel: WakeLockSentinel) => this.wakeLock = sentinel));
		}
		catch (error: any) {
			this.wakeLock = null;
		}

		return of(null);
	}

	/**
	 * Releases the WakeLock and sets it to null
	 * @returns Observable<void> if WakeLock released or Observable<null> if WakeLock already null
	 */
	public ReleaseWakeLock$(): Observable<void | null> {
		if (this.wakeLock == null)
			return of(null);

		return defer(() => from(this.wakeLock!.release())).pipe(tap(() => this.wakeLock = null));
	}
}