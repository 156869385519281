import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ValidationErrorDirective } from './validation-error.directive';

/**
 * This module is for the validation error directive - used to apply proper styling to error div's.
 */
@NgModule({
	declarations: [
		ValidationErrorDirective
	],
	imports: [
		CommonModule
	],
	exports: [
		ValidationErrorDirective
	]
})
export class ValidationErrorModule { }