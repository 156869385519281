import { DialogService } from './../../dialog/dialog.service';
import { ToastService } from './../../toast/toast.service';
import { LicenseDataService } from './../license-data.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Agent } from '../agent';
import { UUID } from 'angular2-uuid';
import { AuthorizationService } from '../authorization.service';
import { UserStateService } from '../../user-state/user-state.service';
import { Authorization } from '../../authorization/authorization';
import { DialogComponent } from 'src/app/dialog/dialog/dialog.component';
import { AuthorizationConstants } from '../authorization-constants';
import { TitleService } from 'src/app/layout/title.service';
import { LoggerService } from 'src/app/telemetry/logger.service';
import { TelemetryIdentifiers } from 'src/app/telemetry/telemetry-identifiers';

/**
 * This component is used to select the crown Id authorization for the user.
 */
@Component({
	selector: 'crown-x-authorize',
	templateUrl: './authorize.component.html',
	styleUrls: ['./authorize.component.css']
})
export class AuthorizeComponent implements OnInit, OnDestroy {

	/** The list of crown identifiers from which to select. */
	public AuthorizedAgents!: Agent[];

	/** Indicates whether the error message should be shown. */
	public ShowError: boolean = false;

	/** Indicates if the crown indentifiers are being loaded. */
	public Loading: boolean = true;

	/** The list of licenses that could not be allocated to the user. */
	public UnallocatedLicenses: string[] = [];

	/** A reference to the dialog for showing unavailable licenses. */
	@ViewChild("UnavailableLicensesDialog")
	public UnavailableLicensesDialog!: DialogComponent;

	/** The current user authorization. */
	private userAuthorization: Authorization | null = null;

	/** Used to unsubscribe from observables. */
	private unsubscriber = new Subject();

	/**
	 * @constructor
	 *
	 * @param titleService The service used to set the page title.
	 * @param authorizationService The authorization service.
	 * @param router The Angular router service.
	 * @param userStateService The user state service.
	 * @param licenseDataService The service used to allocate licenses.
	 * @param toastService The service used to show toast messages.
	 * @param dialogService The service used to show and hide dialogs.
	 * @param logger The logging service.
	 */
	constructor(
		private readonly titleService: TitleService,
		private readonly authorizationService: AuthorizationService,
		private readonly router: Router,
		private readonly userStateService: UserStateService,
		private readonly licenseDataService: LicenseDataService,
		private readonly toastService: ToastService,
		private readonly dialogService: DialogService,
		private readonly logger: LoggerService
	) { }

	/**
	 * Component initialization.
	 */
	public ngOnInit(): void {
		this.titleService.Title = "Authorization";
		this.authorizationService.GetAuthorization$()
			.pipe(takeUntil(this.unsubscriber))
			.subscribe({
				next: (auth: Authorization) => {
					this.userAuthorization = auth;
					if (auth.AuthorizedAgents.length == 0) {
						this.ShowError = true;
						this.Loading = false;
					}
					else if (auth.AuthorizedAgents.length == 1) {
						this.SetCrownIdentifier(auth.AuthorizedAgents[0].CrownIdentifier);
						if (this.userAuthorization?.UserAgentAuthorizations) {
							this.SetLicenses();
						}
						else {
							this.toastService.ShowError("You are not currently authorized for any licenses for this agent.");
						}
						this.SetAuthorizedAgentsList();
						this.Loading = false;
					}
					else {
						this.SetAuthorizedAgentsList();
						this.Loading = false;
					}
				},
				error: (onError) => {
					this.ShowError = true;
					this.Loading = false;
				}
			});
	}

	/**
	 * Component destruction.
	 */
	public ngOnDestroy(): void {
		this.unsubscriber.next(null);
		this.unsubscriber.complete();
	}

	/**
	 * Handler for when a crown identifier is selected.
	 * @param crownIdentifier The selected crown identifier
	 */
	public OnSelectCrownIdentifier(crownIdentifier: string): void {
		this.Loading = true;
		if (crownIdentifier != "") {
			if (this.userStateService.State.CrownIdentifier != crownIdentifier) {
				if (!this.userStateService.State.FilterTelemetry)
					this.logger.TrackEvent(TelemetryIdentifiers.EVENT_SELECTED_AGENT_CROWNID, { CrownIdentifier: crownIdentifier, UserName: this.userStateService.State.Username });
				this.SetCrownIdentifier(crownIdentifier);
				if (this.userAuthorization?.Roles?.includes(AuthorizationConstants.AGENT_LICENSE_BYPASS_ROLE)) {
					this.Navigate();
				}
				else if (this.userAuthorization?.UserAgentAuthorizations) {
					this.SetLicenses();
				}
				else {
					this.Loading = false;
					this.toastService.ShowError("You are not currently authorized for any licenses for this agent.");
				}
			}
			else {
				this.Navigate();
			}
		}
	}

	/**
	 * Navigates to the features landing page.
	 */
	public Navigate(): void {
		this.router.navigate(['/features']);
	}

	/**
	 * Event handler for when Ok is clicked on the Unavailable Licenses dialog.
	 */
	public OnUnavailableLicensesOkClick(): void {
		this.dialogService.HideModalDialog(this.UnavailableLicensesDialog);
		this.Navigate();
	}

	/**
	 * Event handler for when Cancel is clicked on the Unavailable Licenses dialog.
	 */
	public OnUnavailableLicensesCancelClick(): void {
		this.Loading = false;
		this.dialogService.HideModalDialog(this.UnavailableLicensesDialog);
	}

	/**
	 * Sets the Crown ID for the current user.
	 *
	 * @param crownIdentifier The Crown ID to set.
	 */
	private SetCrownIdentifier(crownIdentifier: string): void {
		this.userStateService.SetCrownIdentifier(crownIdentifier);
	}

	/**
	 * Sets the user's licenses based on their authorizations for the crown identifier selected.
	 */
	private SetLicenses(): void {
		if (this.userAuthorization?.Roles?.includes(AuthorizationConstants.AGENT_LICENSE_BYPASS_ROLE)) {
			this.Navigate();
			return;
		}
		this.licenseDataService.AllocateLicenses$()
			.pipe(takeUntil(this.unsubscriber))
			.subscribe({
				next: (unallocatedLicenses: string[]) => {
					this.authorizationService.GetAuthorization$()
						.pipe(takeUntil(this.unsubscriber))
						.subscribe((auth: Authorization) => {
							this.userAuthorization = auth;
							if (unallocatedLicenses.length > 0) {
								this.UnallocatedLicenses = unallocatedLicenses;
								this.dialogService.ShowModalDialog(this.UnavailableLicensesDialog);
							}
							else {
								this.Navigate();
							}
						});
				},
				error: () => {
					this.Loading = false;
					this.toastService.ShowError("An error occurred while attempting to authorize your account. You may need to log out and log back in.");
				}
			});
	}

	/**
	 * Sets the authorized agents list for the dropdown.
	 */
	private SetAuthorizedAgentsList(): void {
		const agents = this.userAuthorization ? this.userAuthorization.AuthorizedAgents.sort((agentX, agentY) => (agentX.CompanyName.toLowerCase() < agentY.CompanyName.toLowerCase() ? -1 : 1)) : [];
		agents.splice(0, 0, {
			Id: UUID.UUID(),
			CrownIdentifier: "",
			CompanyName: "",
			Address: null,
			Licenses: [],
			CanImportCfmDrivers: false,
			IsMobileMigrationComplete: false,
			DisabledDate: null,
			IsDisabled: false,
			LegacyMobileApiGatewayKey: null,
			SamsaraApiKey: null
		})
		this.AuthorizedAgents = agents.filter(agent => !agent.IsDisabled);
	}
}